import { useEffect, useState } from 'react';
import { postUploadFiles } from '#api/img-upload';
import { deleteMediaFiles } from '#api/records';
import { useToast } from '#providers/ToastProvider';
import { UpLoadFilePathType } from '#types/gyms';
import { Images } from '#types/images';
import { IPostFiles } from '#types/upload';
import { createImgToFormData } from '#utils/createImgToFormData';
import { defaultMaxCount } from '.';

export interface Image {
  name: string;
  url: string;
  file: File;
  loading?: boolean;
}

interface Props {
  images?: Images[];
  maxCount?: number;
  fileType: UpLoadFilePathType;
  registeredLectureId?: string;
}

export interface FileType {
  id: string;
  type: string;
  uploadedLink?: string;
}

/**
 * @description 요구사항 영상 압축하기
 * @returns
 */
export const useUploadImages = ({
  images,
  fileType,
  maxCount = defaultMaxCount,
  registeredLectureId
}: Props) => {
  const [localImgs, setLocalImgs] = useState<Image[]>([]);
  const [postedImgs, setPostedImgs] = useState<Images[]>(images ?? []);
  const [getFileInfo, setGetFileInfo] = useState<IPostFiles[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showToast } = useToast();
  // const ffmpegRef = useRef<FFmpeg | null>(null);
  // const loadFFmpeg = async () => {
  //   if (!ffmpegRef.current) {
  //     ffmpegRef.current = createFFmpeg();
  //     await ffmpegRef.current.load();
  //   }
  // };

  useEffect(() => {
    return () => {
      localImgs.forEach(image => {
        URL.revokeObjectURL(image.url);
      });
    };
  }, []);
  const handleUploadImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;

    if (!selectedFiles) return;
    const newFiles = Array.from(selectedFiles);

    // const fileNames = newFiles.map(item => item.name);
    // const filteredFiles = localImgs.filter(
    //   item => !fileNames.includes(item.name)
    // );

    // if (filteredFiles.length + newFiles.length > maxCount) {
    if (localImgs.length + postedImgs.length + newFiles.length > maxCount) {
      showToast({
        message: `이미지는 최대 ${maxCount}장까지 업로드 가능합니다.`,
        type: 'fail'
      });
      return;
    }

    // 이미지 정보를 생성 및 URL 저장
    const newImages = newFiles.map(file => ({
      name: file.name,
      url: URL.createObjectURL(file),
      file: file
    }));

    setLocalImgs(prev => [...prev, ...newImages]);
    e.target.value = ''; // 같은 파일 입력 허용을 위해 값을 비움
  };

  const handleUploadChartFiles = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFiles = e.target.files;

    if (!selectedFiles) return;
    const newFiles = Array.from(selectedFiles);

    // const fileNames = newFiles.map(item => item.name);
    // const filteredFiles = localImgs.filter(
    //   item => !fileNames.includes(item.name)
    // );

    // if (filteredFiles.length + newFiles.length > maxCount) {
    if (localImgs.length + postedImgs.length + newFiles.length > maxCount) {
      showToast({
        message: `이미지는 최대 ${maxCount}장까지 업로드 가능합니다.`,
        type: 'fail'
      });
      return;
    }

    // 이미지 정보를 생성 및 URL 저장
    const newImages = newFiles.map(file => ({
      name: file.name,
      url: URL.createObjectURL(file),
      file: file,
      loading: true
    }));
    setLocalImgs(prev => [...prev, ...newImages]);

    // 이미지 전송후 id 응답
    try {
      Promise.allSettled(
        newImages.map(data =>
          postUploadFiles({
            path: convertUploadFilePath(
              data.file.type.includes('image'),
              fileType
            ),
            type: data.file.type.includes('image') ? 'image' : 'video',
            images: createImgToFormData([data]) // 각 파일을 개별적으로 처리
          })
        )
      ).then(results => {
        results.forEach((result, index) => {
          if (result.status === 'fulfilled') {
            // 업로드 성공, result.value에 응답 데이터가 있음
            newImages[index].url = result.value.uploadedLink; // uploadedLink에 접근하여 URL 업데이트
            setLocalImgs(prev =>
              prev.map(image => ({
                ...image,
                loading: false
              }))
            );
            setGetFileInfo(prev => [...prev, result.value]);
          } else {
            console.error(result.reason);
          }
        });
      });

      // setGetFileInfo(prev => [...prev, fileInfo]);
      e.target.value = ''; // 같은 파일 입력 허용을 위해 값을 비움
    } catch {
      showToast({
        message: '이미지 업로드 중 문제가 발생했습니다.',
        type: 'fail'
      });
    }
  };

  const handleDeletePostedImage = (imgId: string) => {
    setPostedImgs(prev => prev.filter(img => img.id !== imgId));
  };

  const handleDeleteLocalImage = (itemUrl: string) => {
    setLocalImgs(prev => prev.filter(file => file.url !== itemUrl));
    setGetFileInfo(prev => prev.filter(file => file.uploadedLink !== itemUrl));
    const itemID = [...getFileInfo]
      .filter(fileInfo => fileInfo.uploadedLink === itemUrl)
      .map(item => item.id);
    registeredLectureId &&
      deleteMediaFiles(registeredLectureId || '', itemID)
        .then(() =>
          showToast({
            message: '파일을 성공적으로 삭제하였습니다.',
            type: 'success'
          })
        )
        .catch(() =>
          showToast({
            message: '파일 삭제를 실패하였습니다.',
            type: 'fail'
          })
        );
  };

  const convertUploadFilePath = (
    type: boolean,
    path: UpLoadFilePathType
  ): UpLoadFilePathType => {
    if (fileType === 'exercise-image' && !type) {
      return 'exercise-video';
    } else {
      return path;
    }
  };

  return {
    localImgs,
    handleUploadImages,
    handleDeleteLocalImage,
    postedImgs,
    handleDeletePostedImage,
    maxCount,
    getFileInfo,
    handleUploadChartFiles
  };
};
