import styled from 'styled-components';

export const TitleContainer = styled.div`
  width: 100%;
  height: 15rem;

  & > p:last-child {
    margin-top: 1rem;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.8;
`;

export const BottomContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`;
