import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { postInquiries } from '#api/inquiries';
import { Textarea } from '#atoms';
import { PATH } from '#const/path';

export const HelpOpinion = () => {
  const [opinionText, setOpinionText] = useState('');
  const { mutate: sendInquiries } = useMutation(postInquiries);
  const navigate = useNavigate();

  const handleSendButtonClick = () => {
    // 기획 변경에 따라 문의사항의 제목은 사라졌으나, 백엔드 API는 수정되지 않았기 때문에
    // 제목을 고정된 값으로 보내게 처리하였음
    sendInquiries({ title: '문의', body: opinionText });
    navigate(PATH.HELP);
  };

  return (
    <>
      <Textarea
        value={opinionText}
        onChange={setOpinionText}
        height={17}
        limit={1000}
        placeholder="피벗을 이용하면서 아쉬웠던 부분을 솔직하게 말씀해주세요"
      />
    </>
  );
};
