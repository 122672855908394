import { useEffect, useState } from 'react';
import { Button, InlineSelect, Input, Text, Textarea } from '#atoms';
import { PATH } from '#const/path';
import { usePriceInput } from '#hooks/usePriceInput';
import {
  ImageWithUpload,
  LabeledInput,
  SessionAdderBox,
  TitleItemBox
} from '#molecules';
import { initialLecture, useCoachLecture } from '#providers/CoachLecture';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { imageFileType } from '#types/fileType';
import { Curriculum, IPostCoachLecture, SessionType } from '#types/lectures';
import { formatNumberWithCommas } from '#utils/Numbers';
import { useUploadImages } from 'src/components/molecules/ImageWithUpload/hooks';
import { useModifyCoachLecture } from './hooks/useModifyCoachLecture';
import { useSubmitCoachLecture } from './hooks/useSubmitCoachLecture';
import {
  ButtonContainer,
  CheckBox,
  Container,
  CurriculaArea,
  CurriculaContainer,
  MultiSesstionPriceContainer,
  SelectArea
} from './styles';

const recommendedRounds = ['1', '2', '3', '4', '5', '6', '7'];
const totalRounds = Array.from({ length: 30 }, (_, i) => `${i + 1}`);
const maxCount = 10;

export function ProfileClassFormPage() {
  const { lectureId, lecture, curriculum, images } = useCoachLecture();
  const { price, handlePriceInput } = usePriceInput(
    lecture.price === 0 ? undefined : lecture.price
  );

  const [form, setForm] = useState<IPostCoachLecture>(
    lecture ?? initialLecture
  );

  const [curricula, setCurriculum] = useState<Curriculum[]>(
    curriculum ? curriculum : []
  );
  const [multiCountPrice, setMultiSessionDiscountPrice] = useState<
    SessionType[]
  >(
    lecture.lectureSessionPackage
      ? lecture.lectureSessionPackage
      : [
          {
            session: 10,
            price: null
          }
        ]
  );

  const [isMultiSessionDiscount, setIsMultiSesstionDiscount] =
    useState<boolean>(false);
  const [isWeeklySession, setIsWeeklySession] = useState<boolean>(false);

  const revenue = price
    ? Math.floor((price - price * 0.033 * 1.1) / 10) * 10
    : 0;

  useEffect(() => {
    lecture.lectureSessionPackage &&
      lecture.lectureSessionPackage?.length > 0 &&
      setIsMultiSesstionDiscount(true);
    curriculum.length > 0 && setIsWeeklySession(true);
  }, []);

  const {
    localImgs,
    handleUploadImages,
    handleDeleteLocalImage,
    postedImgs,
    handleDeletePostedImage
  } = useUploadImages({ fileType: 'profile', images, maxCount });

  useEffect(() => {
    setForm(prev => ({ ...prev, price: price ?? 0, netRevenue: revenue }));
  }, [price]);

  const onFormChange = (value: Partial<IPostCoachLecture>) => {
    setForm(prev => ({
      ...prev,
      ...value
    }));
  };

  const onCurriculaChange = (e: string, index: number) => {
    setCurriculum(prev => {
      prev[index].description = e;
      return [...prev];
    });
  };

  const onAddCurricula = () => {
    setCurriculum(prev => [
      ...prev,
      {
        order: curricula.length + 1,
        description: ''
      }
    ]);
  };

  const addMultiSessionCount = () => {
    setMultiSessionDiscountPrice(prev => [
      ...prev,
      { session: (prev.length + 1) * 10, price: null }
    ]);
  };

  const handleMultiPriceInput = (e: string, index: number) => {
    const maxPrice = 1000000;
    if (e.charAt(0) === '0' && e.length > 1) return;
    if (e === '') {
      setMultiSessionDiscountPrice(prev =>
        prev.map((item, idx) =>
          idx === index ? { ...item, price: null } : item
        )
      );
      return;
    }
    const valueRaw = parseInt(e.replaceAll(',', ''), 10);
    if (!isNaN(valueRaw) && (maxPrice === undefined || valueRaw <= maxPrice)) {
      setMultiSessionDiscountPrice(prev =>
        prev.map((item, idx) =>
          idx === index ? { ...item, price: valueRaw } : item
        )
      );
    }
  };

  const onIsTitleChange = () => {
    setForm(prev => ({ ...prev, isTitle: !prev.isTitle }));
  };

  const submitLecture = useSubmitCoachLecture();
  const modifyLecture = useModifyCoachLecture();

  const handlePostButtonClick = () => {
    submitLecture({ form, curricula, localImgs, multiCountPrice });
  };

  const handleModifyButtonClick = () => {
    modifyLecture({
      lectureId,
      form,
      curricula,
      localImgs,
      images,
      postedImgs,
      multiCountPrice
    });
  };

  return (
    <>
      <PrevHeaderBarWithTitleTemplate
        prevRoute={PATH.PROFILE_CLASS}
        title="정규 수업 등록"
        defaultBackground
      >
        <Container>
          <Text textStyle="t1">수업 내용을 입력해 주세요</Text>
          <TitleItemBox title="수업 이름">
            <Input
              type="text"
              placeholder="수업 이름을 적어주세요"
              value={form.title}
              maxLength={29}
              onChange={e => onFormChange({ title: String(e) })}
            />
            <Text
              textStyle="c1"
              textColor="gray2"
              style={{ textAlign: 'right' }}
            >{`${form.title.length} / 30`}</Text>
          </TitleItemBox>

          <TitleItemBox title="수업 소개">
            <Textarea
              value={form.body}
              onChange={e => onFormChange({ body: e })}
              height={12.8}
              limit={400}
              placeholder="수업 소개글을 적어주세요"
            />
          </TitleItemBox>

          <TitleItemBox title="수업 사진" type="images" isOptional>
            <ImageWithUpload
              postedImages={postedImgs}
              localImages={localImgs}
              onUploadImage={handleUploadImages}
              onDeleteLocalImage={handleDeleteLocalImage}
              onDeletePostedImage={handleDeletePostedImage}
              maxCount={maxCount}
              fileType={imageFileType}
            />
          </TitleItemBox>

          <LabeledInput
            label="수업 가격"
            labelStyle="t3"
            placeholder="가격을 입력해주세요"
            value={price !== undefined ? formatNumberWithCommas(price) : ''}
            suffix="원/1회"
            onInput={handlePriceInput}
          />
          <TitleItemBox title="다회차 가격 할인" type="row-option">
            <Text textStyle="c1" textColor="gray2">
              (선택)
            </Text>
            <CheckBox
              isChecked={isMultiSessionDiscount}
              onClick={() => setIsMultiSesstionDiscount(prev => !prev)}
            />
          </TitleItemBox>

          {isMultiSessionDiscount && (
            <CurriculaArea>
              {multiCountPrice.map((item, index) => (
                <MultiSesstionPriceContainer key={index}>
                  <Text children={`${item.session}회 이상`} />
                  <LabeledInput
                    labelStyle="t3"
                    placeholder="가격을 입력해주세요"
                    value={
                      item.price !== null
                        ? formatNumberWithCommas(item.price)
                        : ''
                    }
                    suffix="원/1회"
                    onInput={e => handleMultiPriceInput(e, index)}
                    label={''}
                  />
                </MultiSesstionPriceContainer>
              ))}
              <SessionAdderBox onClickFuc={() => addMultiSessionCount()} />
            </CurriculaArea>
          )}

          <TitleItemBox title="주차별 수업 계획" type="row-option">
            <Text textStyle="c1" textColor="gray2">
              (선택)
            </Text>
            <CheckBox
              isChecked={isWeeklySession}
              onClick={() => {
                setIsWeeklySession(prev => !prev);
              }}
            />
          </TitleItemBox>

          {isWeeklySession && (
            <CurriculaArea>
              {curricula.map((item: Curriculum, index: number) => (
                <CurriculaContainer key={index}>
                  <Text>{`${item.order}주차`}</Text>
                  <Textarea
                    value={item.description}
                    onChange={e => onCurriculaChange(e, index)}
                    height={8.45}
                    limit={50}
                    placeholder="수업 계획을 적어주세요"
                  />
                </CurriculaContainer>
              ))}
              <SessionAdderBox onClickFuc={onAddCurricula} />
            </CurriculaArea>
          )}

          <TitleItemBox title="권장 횟수" type="row">
            <SelectArea>
              <Text textColor="gray2">1주일에</Text>
              <InlineSelect
                data={recommendedRounds}
                value={
                  form.recommendRoundPerWeek
                    ? `${form.recommendRoundPerWeek}`
                    : ''
                }
                onClick={e =>
                  onFormChange({ recommendRoundPerWeek: Number(e) })
                }
              />
              <Text textColor="gray2">회</Text>
            </SelectArea>
          </TitleItemBox>

          <div>
            <TitleItemBox title="대표 강의로 설정" type="row">
              <CheckBox
                isChecked={form.isTitle ?? false}
                onClick={onIsTitleChange}
              />
            </TitleItemBox>
            <Text textStyle="c1" textColor="gray2">
              설정된 강의의 금액이 대표금액으로 노출됩니다
            </Text>
          </div>
        </Container>
      </PrevHeaderBarWithTitleTemplate>

      <ButtonContainer>
        <Button
          disabled={!(form.title && form.body && form.price)}
          onClick={lectureId ? handleModifyButtonClick : handlePostButtonClick}
        >
          완료
        </Button>
      </ButtonContainer>
    </>
  );
}
