import { Link } from 'react-router-dom';
import { Text } from '#atoms';
import { Account } from '#types/account';
import { dottedDateFormatter } from '#utils/dateFormatter';
import {
  CoachInformation,
  CoachInformationList,
  DetailsWrapper
} from './styles';

interface Props {
  coachData: Account;
}

export const CoachIntroduce = ({ coachData }: Props) => {
  const { introduction, careers, licenses, degrees, association, educations } =
    coachData;

  return (
    <CoachInformationList>
      <CoachInformation>
        <Text textStyle="t3">소개글</Text>
        <Text textColor="gray2" style={{ whiteSpace: 'pre-wrap' }}>
          {introduction ?? '없음'}
        </Text>
      </CoachInformation>
      {degrees.length > 0 &&
        degrees.filter(degree => degree.status === 'NORMAL').length > 0 && (
          <CoachInformation>
            <Text textStyle="t3">학력사항</Text>
            <DetailsWrapper>
              {degrees
                .filter(degree => degree.status === 'NORMAL')
                .map(degree => (
                  <Text textColor="gray2" key={degree.id}>
                    {`${degree.name}${
                      degree.at
                        ? dottedDateFormatter(degree.at) === '1900.01.01'
                          ? ''
                          : ` (${dottedDateFormatter(degree.at)})`
                        : ' (재학중)'
                    }`}
                  </Text>
                ))}
            </DetailsWrapper>
          </CoachInformation>
        )}
      {licenses.length > 0 &&
        licenses.filter(license => license.status === 'NORMAL').length > 0 && (
          <CoachInformation>
            <Text textStyle="t3">자격사항</Text>
            <DetailsWrapper>
              {licenses
                .filter(license => license.status === 'NORMAL')
                .map(license => (
                  <Text
                    textColor="gray2"
                    key={license.id}
                    isLink={!!license.licenseUrl}
                    route={'/license/' + license.licenseUrl}
                  >
                    {`${license.name}${
                      license.gotAt
                        ? ` (${dottedDateFormatter(license.gotAt)})`
                        : ''
                    }`}
                  </Text>
                ))}
            </DetailsWrapper>
          </CoachInformation>
        )}
      {careers.length > 0 &&
        careers.filter(career => career.status === 'NORMAL').length > 0 && (
          <CoachInformation>
            <Text textStyle="t3">경력사항</Text>
            <DetailsWrapper>
              {careers
                .filter(career => career.status === 'NORMAL')
                .map(career => (
                  <Text textColor="gray2" key={career.id}>
                    {`${career.name} (${dottedDateFormatter(
                      career.startAt
                    )} ~ ${
                      career.endAt ? dottedDateFormatter(career.endAt) : '현재'
                    })`}
                  </Text>
                ))}
            </DetailsWrapper>
          </CoachInformation>
        )}
      {educations.length > 0 &&
        educations.filter(education => education.status === 'NORMAL').length >
          0 && (
          <CoachInformation>
            <Text textStyle="t3">교육사항</Text>
            <DetailsWrapper>
              {educations
                .filter(education => education.status === 'NORMAL')
                .map(education => (
                  <Text
                    textColor="gray2"
                    key={education.id}
                    isLink={!!education?.educationUrl}
                    route={`/edu/${education.educationUrl}`}
                  >
                    {`${education.name}${
                      education.gotAt
                        ? ` (${dottedDateFormatter(education.gotAt)})`
                        : ''
                    }`}
                  </Text>
                ))}
            </DetailsWrapper>
          </CoachInformation>
        )}
      {association.length > 0 &&
        association.filter(association => association.status === 'NORMAL')
          .length > 0 && (
          <CoachInformation>
            <Text textStyle="t3">소속협회</Text>
            <DetailsWrapper>
              {association
                .filter(association => association.status === 'NORMAL')
                .map(association => (
                  <Text
                    textColor="gray2"
                    key={association.id}
                    isLink={!!association.associationUrl}
                    route={`/team/${association.associationUrl}`}
                  >
                    {association.name}
                  </Text>
                ))}
            </DetailsWrapper>
          </CoachInformation>
        )}
    </CoachInformationList>
  );
};
