import { Route, Routes } from 'react-router';
import { LicensePage } from 'src/components/pages/LicensePage';

export function LicenseView() {
  console.log();
  return (
    <Routes>
      <Route path="/:licenseUrl" element={<LicensePage />} />
    </Routes>
  );
}
