import styled from 'styled-components';

export const LectureInfoItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem 0;
  border-bottom: 1px solid var(--color-border);
`;

export const ImageList = styled.ul`
  display: flex;
  gap: 0.75rem;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const ImageItem = styled.li`
  width: 9.25rem;
  height: 9.25rem;

  img {
    width: 9.25rem;
    height: 9.25rem;
    object-fit: cover;
    border-radius: 0.5rem;
  }
`;

export const MainInfoArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 2.25rem;
  color: var(--color-white);
`;

export const SubInfoArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  height: auto;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  &.hidden {
    display: none;
  }
`;

export const PriceArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > p {
    display: flex;
    align-items: center;
  }

  & > p > span.per {
    margin-right: 0.5rem;
    color: var(--color-point);
  }

  & > p > span.won {
    margin-left: 0.5rem;
  }

  & > p:last-child {
    padding: 0.4rem;
    border-radius: 0.4rem;
    background-color: var(--color-point-second);
    color: var(--color-point-fourth);
  }
`;

export const IntroductionArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const CurriculaArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const CurriculaItem = styled.li`
  display: flex;
  align-items: center;
  color: var(--color-gray-text-second);

  & > p.order {
    flex-basis: 20%;
    padding: 0.5rem 1rem;
  }

  & > p.desc {
    flex-basis: 80%;
    flex-wrap: wrap;
    word-break: break-all;
    padding: 0.5rem 1rem;
    border-left: 1px solid var(--color-gray-text-second);
  }
`;

export const ToggleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.25rem;

  &.hidden > svg {
    transform: rotate(-90deg);
  }

  svg {
    transform: rotate(90deg);
  }
`;
export const PricePerOnceArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;
