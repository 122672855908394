import classNames from 'classnames';
import React, { useState } from 'react';
import { PrevArrow } from '#assets/svg';
import { Text } from '#atoms';
import { Modal } from '#molecules';
import { Lecture } from '#types/lectures';
import { formatNumberWithCommas } from '#utils/Numbers';
import {
  LectureInfoItemWrap,
  ImageList,
  ImageItem,
  MainInfoArea,
  PriceArea,
  SubInfoArea,
  IntroductionArea,
  CurriculaArea,
  CurriculaItem,
  ToggleButton,
  PricePerOnceArea
} from './styles';

interface Props {
  data: Lecture;
}

export const LectureInfoItem = ({ data }: Props) => {
  // const [showSubArea, setShowSubArea] = useState(true);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [detailImage, setDetailImage] = useState<string | null>(null);

  const handleDetailClick = (image: string | null) => {
    setDetailImage(image);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setDetailImage(null);
  };

  const latestLecturePackage =
    data.lectureSessionPackage.length > 0
      ? data.lectureSessionPackage[data.lectureSessionPackage.length - 1]
      : null;

  const priceToDisplay = latestLecturePackage
    ? latestLecturePackage.price?.toLocaleString('ko-KR')
    : data.price.toLocaleString('ko-KR');

  return (
    <LectureInfoItemWrap>
      <ImageList>
        {data.images.map(imgSrc => (
          <ImageItem key={imgSrc.id}>
            <img
              src={imgSrc.resizedLink}
              onClick={() => handleDetailClick(imgSrc.uploadedLink)}
            />
          </ImageItem>
        ))}
      </ImageList>
      <MainInfoArea>
        <PriceArea>
          <Text textStyle="t2">
            <Text textStyle="t3" type="inline" className="per">
              1회
            </Text>
            {priceToDisplay}
            <Text textStyle="t3" type="inline" className="won">
              {data.lectureSessionPackage.length === 0 ? '원' : '원~'}
            </Text>
          </Text>
          <Text textStyle="c1">
            {data.recommendRoundPerWeek &&
              `주 ${data.recommendRoundPerWeek}회 권장`}
          </Text>
        </PriceArea>
        <Text textStyle="t3">{data.title}</Text>
      </MainInfoArea>
      <SubInfoArea className={classNames({ hidden: false })}>
        <IntroductionArea>
          <Text textStyle="t3">수업소개</Text>
          <Text textColor="gray2" style={{ whiteSpace: 'pre-wrap' }}>
            {data.body}
          </Text>
        </IntroductionArea>
        {data.curricula.length > 0 && (
          <CurriculaArea>
            <Text textStyle="t3">커리큘럼</Text>
            <ul>
              {data.curricula.map(curricula => (
                <CurriculaItem key={curricula.id}>
                  <Text className="order">{curricula.order}주차</Text>
                  <Text className="desc" style={{ whiteSpace: 'pre-wrap' }}>
                    {curricula.description}
                  </Text>
                </CurriculaItem>
              ))}
            </ul>
          </CurriculaArea>
        )}

        {data.lectureSessionPackage.length > 0 && (
          <PricePerOnceArea>
            <Text textStyle="t3">PT 가격</Text>
            <ul>
              <CurriculaItem>
                <Text className="order">{1}회~</Text>
                <Text className="desc" style={{ whiteSpace: 'pre-wrap' }}>
                  {formatNumberWithCommas(data.price)}원/회
                </Text>
              </CurriculaItem>
              {data.lectureSessionPackage.map(({ session, price }, idx) => (
                <CurriculaItem key={idx}>
                  <Text className="order">{session}회~</Text>
                  <Text className="desc" style={{ whiteSpace: 'pre-wrap' }}>
                    {price && formatNumberWithCommas(price)}원/회
                  </Text>
                </CurriculaItem>
              ))}
            </ul>
          </PricePerOnceArea>
        )}
      </SubInfoArea>
      {/* <ToggleButton
        className={classNames({ hidden: !showSubArea })}
        onClick={handleToggleButtonClick}
      >
        <PrevArrow />
      </ToggleButton> */}
      {modalOpen && detailImage && (
        <Modal onClose={handleModalClose} transparent>
          <img className="modal-img" src={detailImage} />
        </Modal>
      )}
    </LectureInfoItemWrap>
  );
};
