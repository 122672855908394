import { deleteProfileImgs, getAccount, postProfileImgs } from '#api/accounts';
import { useToast } from '#providers/ToastProvider';
import { useUserInfo } from '#providers/UserInfo';
import { Profile } from '#types/account';

const useUploadProfile = ({
  images,
  maxCount
}: {
  images: Profile[];
  maxCount: number;
}) => {
  const { userInfo, setUserInfo } = useUserInfo();
  const { showToast } = useToast();

  const handlePostImages = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;

    if (!selectedFiles) return;
    const newFiles = Array.from(selectedFiles);
    if (images.length + newFiles.length > maxCount) {
      showToast({
        message: `이미지는 최대 ${maxCount}장까지 등록 가능합니다.`,
        type: 'fail'
      });
      return;
    }

    const formData = new FormData();

    newFiles.forEach(image => {
      formData.append('profile', image);
    });

    postProfileImgs({ profileImgs: formData })
      .then(res => {
        showToast({ message: '이미지 등록에 성공하였습니다.' });
        getAccount(userInfo?.id ?? '').then(res => {
          const updatedProfileImgs = res.profile;
          updatedProfileImgs &&
            userInfo &&
            setUserInfo({ ...userInfo, profile: updatedProfileImgs });
        });
      })
      .catch(error =>
        showToast({ type: 'fail', message: '이미지 등록에 실패하였습니다.' })
      );
  };
  const handleDeleteImage = async (imgId: string) => {
    deleteProfileImgs({ profileIds: [imgId] })
      .then(res => {
        showToast({ message: '이미지 삭제에 성공하였습니다.' });
        getAccount(userInfo?.id ?? '').then(res => {
          const updatedProfileImgs = res.profile;
          updatedProfileImgs &&
            userInfo &&
            setUserInfo({ ...userInfo, profile: updatedProfileImgs });
        });
      })
      .catch(() =>
        showToast({ type: 'fail', message: '이미지 삭제에 실패하였습니다.' })
      );
  };

  return {
    handlePostImages,
    handleDeleteImage
  };
};

export default useUploadProfile;
