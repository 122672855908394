import { useGetClinicalDataList } from '#api/clinical-data';
import { useGetCoachReviews } from '#api/reviews';
import { BoxIcon, Spacing, Text } from '#atoms';
import { TabListViewer } from '#molecules';
import { ClinicalDataItem } from '#organisims';
import { useUserInfo } from '#providers/UserInfo';
import { $confirmationFormState } from '#states/atoms/confirmationFormState';
import { Account } from '#types/account';
import { Lecture } from '#types/lectures';
import sessionStorageManager from '#utils/sessionStorageManager';
import React, { useEffect, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useNavigate } from 'react-router';
import { useRecoilValue } from 'recoil';
import { ButtonSet } from './ButtonSet';
import { CoachInfo } from './CoachInfo/CoachInfo';
import { CoachIntroduce } from './DetailInfo/CoachIntroduce';
import { CustomerReviews } from './DetailInfo/CustomerReviews';
import { LectureInfoPage } from './DetailInfo/LectureInfo';
import { LecturePlacePage } from './DetailInfo/LecturePlace';
import { ProfileViewer } from './ProfileViewer';
import { ButtonArea, ClinicalDataContainer, Container } from './styles';

interface Props {
  coachData: Account;
  lectureData: Lecture[];
  setShowCoachNameInHeader: React.Dispatch<React.SetStateAction<boolean>>;
  applyTagEvent?: boolean;
  setApplyTagEvent?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CoachDetailContent = ({
  coachData,
  lectureData,
  setShowCoachNameInHeader,
  applyTagEvent,
  setApplyTagEvent
}: Props) => {
  const { userInfo } = useUserInfo();
  const containerRef = useRef<HTMLDivElement>(null);
  const nameLocation = useRef<number>(0);
  const buttonLocation = useRef(0);
  const [showButtonInBottom, setShowButtonInBottom] = useState(false);
  const { data: clinicalDataList } = useGetClinicalDataList({
    coachId: coachData.id,
    offset: 100
  });
  const navigate = useNavigate();
  const { districtSigu } = sessionStorageManager();
  const confirmationFormState = useRecoilValue($confirmationFormState);
  const { data: coachReviews } = useGetCoachReviews({
    coachId: coachData.id
  });
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    return () => setLoaded(false);
  }, []);
  const handleImageLoaded = () => {
    setLoaded(true);
  };

  const hasReviews = !coachReviews?.data.length
    ? ['소개', '수업안내', '수업공간']
    : ['소개', '수업안내', '수업공간', '고객후기'];

  const handleScroll = () => {
    console.log('scroll');
    const currentPosition = containerRef.current?.scrollTop || 0;
    setShowCoachNameInHeader(currentPosition >= nameLocation.current);
    setShowButtonInBottom(currentPosition >= buttonLocation.current);
  };

  const setDataLayer = ({
    event,
    tags
  }: {
    event: string;
    tags?: Record<string, string | string[] | number | null>;
  }) => {
    const tagManagerArgs = {
      dataLayer: {
        event: event,
        coach_id: coachData.id,
        location: districtSigu || '전체',
        tags: coachData?.tags.map(tag => tag.name),
        clinical_data: clinicalDataList?.total ?? 0,
        ...tags
      }
    };

    TagManager.dataLayer(tagManagerArgs);
  };

  useEffect(() => {
    clinicalDataList && setDataLayer({ event: 'view_detail_coach' });
  }, [clinicalDataList]);

  useEffect(() => {
    applyTagEvent &&
      setDataLayer({
        event: 'click_checkout_1',
        tags: {
          option: confirmationFormState.lectureName,
          option_price: confirmationFormState.unitPrice,
          quantity: confirmationFormState.quantity,
          checkout_price:
            (confirmationFormState.unitPrice ?? 0) *
            (confirmationFormState.quantity ?? 0)
        }
      });
    setApplyTagEvent?.(false);
  }, [applyTagEvent]);

  return (
    <Container
      ref={containerRef}
      onScroll={handleScroll}
      className={!userInfo?.isCoach && showButtonInBottom ? 'bottom' : ''}
    >
      <ProfileViewer
        imgSrcList={coachData.profile.map(e => e.uploadedLink) ?? []}
        handleImageLoaded={handleImageLoaded}
        alt="트레이너 프로필 이미지"
      />
      {
        <>
          <CoachInfo
            name={coachData.name}
            tags={coachData.tags}
            gymLocation={coachData.recommendGyms[0]}
            grade={coachData.grade}
            reviewCount={coachData.reviewCount}
            startAthletic={coachData.startAthletic}
            clinicalDataCount={clinicalDataList?.total ?? 0}
            nameLocation={nameLocation}
          />
          {!userInfo?.isCoach ? (
            <ButtonArea>
              <ButtonSet
                coachId={coachData?.id}
                buttonLocation={buttonLocation}
                showButtonInBottom={showButtonInBottom}
                onApplyClick={() =>
                  setDataLayer({ event: 'click_initiatecheckout' })
                }
                onChatClick={() =>
                  setDataLayer({ event: 'click_chat_detail_coach' })
                }
              />
            </ButtonArea>
          ) : (
            <Spacing size={32} />
          )}
          {clinicalDataList && clinicalDataList.data.length > 0 && (
            <ClinicalDataContainer>
              <div
                onClick={() =>
                  navigate(
                    `/expert/clinical-data/${encodeURIComponent(
                      coachData.myUrl || ''
                    )}`
                  )
                }
              >
                <Text textStyle="t3">포트폴리오</Text>
                <BoxIcon type="next-arrow-white" onlyIcon size={14} />
              </div>
              <Text textStyle="c1" textColor="gray2">
                전문가의 PT를 통해 변화된 실제 고객의 데이터 입니다
              </Text>
              <Spacing size={20} />
              <div>
                {clinicalDataList.data.map((data, idx) => (
                  <ClinicalDataItem
                    isPreview
                    data={data}
                    key={idx}
                    onPreviewClick={() =>
                      navigate(
                        `/expert/clinical-data/${encodeURIComponent(
                          coachData.myUrl || ''
                        )}`
                      )
                    }
                  />
                ))}
              </div>
            </ClinicalDataContainer>
          )}
          <TabListViewer initTab={0}>
            <TabListViewer.List tabs={hasReviews} />
            <TabListViewer.Viewer
              tabs={[
                <CoachIntroduce coachData={coachData} />,
                <LectureInfoPage
                  lectureData={lectureData}
                  ot={coachData.ot ?? null}
                  onePoint={coachData.onePoint ?? null}
                />,
                <LecturePlacePage recommendGyms={coachData?.recommendGyms} />,
                <CustomerReviews coachId={coachData.id} />
              ]}
            />
          </TabListViewer>
        </>
      }
    </Container>
  );
};
