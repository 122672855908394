import { useState } from 'react';
import { useNavigate } from 'react-router';
import { modifyCoachLecture, postCoachLecture } from '#api/lectures';
import { Button, Input, Text } from '#atoms';
import { PATH } from '#const/path';
import { usePriceInput } from '#hooks/usePriceInput';
import { LabeledInput } from '#molecules';
import { useCoachLecture } from '#providers/CoachLecture';
import { useToast } from '#providers/ToastProvider';
import { formatNumberWithCommas } from '#utils/Numbers';
import {
  ButtonContainer,
  Container,
  ContentArea,
  HeaderContainer,
  ItemContainer
} from './styles';

interface Props {
  lectureId?: string;
  gymRentalFee: number;
}

export const CoachOTDetailForm = ({ lectureId, gymRentalFee }: Props) => {
  const { showToast } = useToast();
  const { lecture } = useCoachLecture();

  const [description, setDescription] = useState<string>(lecture.body);
  const { price: pricePerRound, handlePriceInput } = usePriceInput(
    lecture.price === 0 ? undefined : lecture.price
  );
  const [round, setRound] = useState<number | undefined>(
    lecture.rounds === 0 ? undefined : lecture.rounds
  );

  const navigate = useNavigate();

  const handlePostButtonClick = async () => {
    pricePerRound !== undefined &&
      round &&
      postCoachLecture({
        title: 'Fibud Exclusive',
        rounds: round,
        isOt: true,
        body: description,
        price: pricePerRound
      })
        .then(() => {
          showToast({
            message: '체험수업 등록에 성공하였습니다.'
          });
          navigate(PATH.PROFILE_UPDATE);
        })
        .catch(error => {
          showToast({
            type: 'fail',
            message: '체험수업 등록에 실패하였습니다.'
          });
        });
  };

  const handleModifyButtonClick = async () => {
    try {
      lectureId &&
        pricePerRound !== undefined &&
        round &&
        (await modifyCoachLecture({
          lectureId: lectureId,
          payload: {
            ...lecture,
            body: description,
            price: pricePerRound,
            rounds: round
          }
        }));
      showToast({
        message: '체험수업 수정에 성공하였습니다.'
      });
      navigate(PATH.PROFILE_UPDATE);
    } catch (error) {
      showToast({
        type: 'fail',
        message: '체험수업 수정에 실패하였습니다.'
      });
    }
  };

  return (
    <>
      <Container>
        <HeaderContainer>
          <Text textStyle="display">체험 수업을 등록해주세요</Text>
        </HeaderContainer>
        <ContentArea>
          <ItemContainer>
            <Text textStyle="t3">수업 소개</Text>
            <Input
              type="text"
              placeholder="수업 소개를 적어주세요"
              value={description}
              onChange={e => setDescription(String(e))}
              maxLength={29}
            />
            <Text
              textStyle="c1"
              textColor="gray2"
              style={{ textAlign: 'right' }}
            >{`${description.length} / 30`}</Text>
          </ItemContainer>
          <ItemContainer className="labeled">
            <LabeledInput
              label="1회 수업 가격"
              labelStyle="t3"
              placeholder="1회 수업 가격을 입력해주세요"
              value={
                pricePerRound !== undefined
                  ? formatNumberWithCommas(pricePerRound)
                  : ''
              }
              suffix="원"
              onInput={handlePriceInput}
            />
            {/* {pricePerRound !== undefined &&
              round !== undefined &&
              pricePerRound * round <= gymRentalFee && (
                <Text textStyle="c1" textColor="red">
                  {`${gymRentalFee.toLocaleString(
                    'ko-KR'
                  )}(대관료) 이하로는 설정할 수 없습니다`}
                </Text>
              )} */}
          </ItemContainer>
          <ItemContainer>
            <Text textStyle="t3">수업 횟수</Text>
            <Input
              type="text"
              placeholder="한 회원이 체험수업을 들을 횟수를 적어주세요"
              value={round !== undefined ? String(round) : ''}
              onChange={e =>
                setRound(e === '' || isNaN(Number(e)) ? undefined : Number(e))
              }
            />
          </ItemContainer>
        </ContentArea>
      </Container>
      <ButtonContainer>
        <Button
          disabled={
            !description.length || pricePerRound === undefined || !round
          }
          onClick={lectureId ? handleModifyButtonClick : handlePostButtonClick}
        >
          확인
        </Button>
      </ButtonContainer>
    </>
  );
};
