import React, { useCallback, useState } from 'react';
import { BoxIcon } from '#atoms';
import { isInputElement } from '#utils/typeguard';
import { MessageInputBoxContainer, Input, ButtonContainer } from './styles';

interface Props {
  onSubmit: (message: string) => void;
}

export const MessageInputBox = ({ onSubmit }: Props) => {
  const [message, setMessage] = useState('');

  const onInputListener = useCallback<React.FormEventHandler<HTMLInputElement>>(
    ({ target }) => {
      if (!isInputElement(target)) return;
      setMessage(target.value);
    },
    []
  );

  const onSubmitListener = useCallback(() => {
    if (!message.trim().length) return;
    onSubmit(message);
    setMessage('');
  }, [message]);

  return (
    <MessageInputBoxContainer>
      <Input
        type="text"
        data-text-type="c1"
        value={message}
        onInput={onInputListener}
      />
      <ButtonContainer>
        <BoxIcon
          type="up-arrow"
          size={20}
          onlyIcon={true}
          onClick={onSubmitListener}
        />
      </ButtonContainer>
    </MessageInputBoxContainer>
  );
};
