import { useEffect, useState } from 'react';
import React from 'react';
import { getLecture } from '#api/lectures';
import { Images } from '#types/images';
import { Curriculum, Lecture, IPostCoachLecture } from '#types/lectures';

interface Props {
  children?: React.ReactNode;
}

interface CoachLectureContext {
  lectureId: string;
  setLectureId: React.Dispatch<React.SetStateAction<string>>;
  lecture: IPostCoachLecture;
  setLecture: React.Dispatch<React.SetStateAction<IPostCoachLecture>>;
  images: Images[];
  setImages: React.Dispatch<React.SetStateAction<Images[]>>;
  curriculum: Curriculum[];
  setCurriculum: React.Dispatch<React.SetStateAction<Curriculum[]>>;
  isLoading: boolean;
  isError: boolean;
  resetLectureData: () => void;
}

export const initialLecture = {
  title: '',
  body: '',
  rounds: 1,
  recommendRoundPerWeek: undefined,
  netRevenue: 0,
  price: 0,
  lectureSessionPackage: [],
  isTitle: false,
  isOt: false
};

export function convertToLectureContext(lecture: Lecture): IPostCoachLecture {
  const {
    title,
    body,
    rounds,
    recommendRoundPerWeek,
    maximumMonth,
    price,
    lectureSessionPackage,
    netRevenue,
    isTitle,
    isOt
  } = lecture;

  return {
    title,
    body,
    rounds,
    recommendRoundPerWeek,
    maximumMonth,
    price,
    lectureSessionPackage,
    netRevenue,
    isTitle,
    isOt
  };
}

const CoachLectureContext = React.createContext<CoachLectureContext | null>(
  null
);

export const useCoachLecture = () => {
  const context = React.useContext(CoachLectureContext);
  if (!context) {
    throw new Error(
      'This component must be used within a <CoachLecture> component.'
    );
  }
  return context;
};

function CoachLecture({ children }: Props) {
  const [lectureId, setLectureId] = useState<string>('');
  const [lecture, setLecture] = useState<IPostCoachLecture>(initialLecture);
  const [images, setImages] = useState<Images[]>([]);
  const [curriculum, setCurriculum] = useState<Curriculum[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [multiSessionDiscount, setMultiSessionDiscount] = useState();

  const resetLectureData = () => {
    setLectureId('');
    setLecture(initialLecture);
    setImages([]);
    setCurriculum([]);
    setIsLoading(false);
    setIsError(false);
  };

  useEffect(() => {
    if (lectureId) {
      setIsLoading(true);
      setIsError(false);
      getLecture(lectureId)
        .then(data => {
          const lectureData = convertToLectureContext(data);
          const imageData: Images[] = data ? data.images : [];
          const curriculaData: Curriculum[] = data
            ? data.curricula.map(({ order, description }) => ({
                order,
                description
              }))
            : [];
          setLecture(lectureData);
          setImages(imageData);
          setCurriculum(curriculaData);
        })
        .catch(() => setIsError(true))
        .finally(() => setIsLoading(false));
    }
  }, [lectureId]);

  const memorizedValue = React.useMemo(
    () => ({
      lectureId,
      setLectureId,
      lecture,
      setLecture,
      images,
      setImages,
      curriculum,
      setCurriculum,
      isLoading,
      isError,
      resetLectureData
    }),
    [
      lectureId,
      setLectureId,
      lecture,
      setLecture,
      images,
      setImages,
      curriculum,
      setCurriculum,
      isLoading,
      isError,
      resetLectureData
    ]
  );

  return (
    <CoachLectureContext.Provider value={memorizedValue}>
      {children}
    </CoachLectureContext.Provider>
  );
}

function IsLoading({ children }: Props) {
  const { isLoading } = useCoachLecture();
  return isLoading ? <>{children}</> : null;
}

function IsError({ children }: Props) {
  const { isLoading, isError } = useCoachLecture();
  return !isLoading && isError ? <>{children}</> : null;
}

function EmptyLecture({ children }: Props) {
  const { isLoading, isError, lecture } = useCoachLecture();
  return !isLoading && !isError && lecture === initialLecture ? (
    <>{children}</>
  ) : null;
}

function HasLecture({ children }: Props) {
  const { isLoading, isError, lecture } = useCoachLecture();
  return !isLoading && !isError && lecture && lecture !== initialLecture ? (
    <>{children}</>
  ) : null;
}

CoachLecture.IsError = IsError;
CoachLecture.IsLoading = IsLoading;
CoachLecture.EmptyLecture = EmptyLecture;
CoachLecture.HasLecture = HasLecture;

export default CoachLecture;
