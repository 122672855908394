import { Text, VideoRenderer } from '#atoms';
import { Modal } from '#molecules';
import { IGetClinicalData } from '#types/clinicalData';
import { imageFileType } from '#types/fileType';
import { getFileExtension } from '#utils/getFileExtension';
import { useState } from 'react';
import {
  DetailContainer,
  ImageContainer,
  PreviewContainer,
  PreviewTitleContainer,
  TitleWithButtonContainer
} from './styles';

interface Props {
  data: IGetClinicalData;
  isPreview?: boolean;
  isCoach?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  onPreviewClick?: () => void;
}

type ModalType = 'video' | 'image';

export const ClinicalDataItem = ({
  data,
  isPreview,
  isCoach,
  onEdit,
  onDelete,
  onPreviewClick
}: Props) => {
  const { lectureName, rounds, body, beforeImages, afterImages, createdAt } =
    data;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [detailImage, setDetailImage] = useState<string>('');
  const [modalType, setModalType] = useState<ModalType>('image');

  const handleDetailClick = (image: string, type: ModalType) => {
    setModalType(type);
    setDetailImage(image);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setDetailImage('');
  };

  return isPreview ? (
    <PreviewContainer onClick={onPreviewClick}>
      <PreviewTitleContainer>
        <Text textStyle="bb">{lectureName}</Text>
        {/* lectureName 최대 1줄 */}
        {/* <Text textStyle="c2" textColor="gray2">
          {dayjs(createdAt).format('YYYY.MM.DD')}
        </Text> */}
      </PreviewTitleContainer>
      <ImageContainer className="preview">
        {beforeImages.map((beforeImage, index) => {
          return (
            <div key={index}>
              <ImageOrVideoRenderer
                url={beforeImage.resizedLink}
                onClick={handleDetailClick}
              />

              <p className="before">전</p>
              <ImageOrVideoRenderer
                url={afterImages[index].resizedLink}
                onClick={handleDetailClick}
              />
              <p className="after">후</p>
            </div>
          );
        })}
      </ImageContainer>
      <Text>{body}</Text>
      {/* body 최대 3줄 */}
    </PreviewContainer>
  ) : (
    <DetailContainer>
      {isCoach ? (
        <TitleWithButtonContainer>
          <div>
            <Text textStyle="bb" type="inline">
              {lectureName}{' '}
            </Text>
            <Text textStyle="bb" textColor="gray2" type="inline">
              {rounds}회
            </Text>
          </div>
          <div>
            <Text type="inline" textStyle="c2" onClick={onEdit}>
              수정
            </Text>
            <Text type="inline" textStyle="c2" onClick={onDelete}>
              삭제
            </Text>
          </div>
        </TitleWithButtonContainer>
      ) : (
        <div>
          <Text textStyle="bb" type="inline">
            {lectureName}{' '}
          </Text>
          <Text textStyle="bb" textColor="gray2" type="inline">
            {rounds}회
          </Text>
        </div>
      )}
      <Text style={{ whiteSpace: 'pre-wrap' }}>{body}</Text>
      <ImageContainer>
        {beforeImages.map((beforeImage, index) => {
          return (
            <div key={index}>
              <ImageOrVideoRenderer
                url={beforeImage.resizedLink}
                onClick={handleDetailClick}
              />

              <p className="before">전</p>
              <ImageOrVideoRenderer
                url={afterImages[index].resizedLink}
                onClick={handleDetailClick}
              />
              <p className="after">후</p>
            </div>
          );
        })}
      </ImageContainer>
      {/* TODO: 임상 기간으로 변경 */}
      {/* <Text textStyle="c2" textColor="gray2">
        {dayjs(createdAt).format('YYYY.MM.DD')}
      </Text> */}
      {modalOpen && (
        <Modal onClose={handleModalClose} transparent>
          {detailImage && modalType === 'image' ? (
            <img className="modal-img" src={detailImage} />
          ) : (
            <VideoRenderer
              className="modal-video"
              controls={true}
              url={detailImage}
            />
          )}
        </Modal>
      )}
    </DetailContainer>
  );
};

const getIsImage = (url: string) => {
  const fileExtension = '.' + getFileExtension(url);

  return imageFileType.split(', ').includes(fileExtension);
};

interface ImageOrVideoRendererProps {
  url: string;
  onClick?: (url: string, type: 'image' | 'video') => void;
}
export const ImageOrVideoRenderer = ({
  url,
  onClick
}: ImageOrVideoRendererProps) => {
  const isImage = getIsImage(url);

  return (
    <div onClick={() => onClick && onClick(url, isImage ? 'image' : 'video')}>
      {isImage ? <img src={url} /> : <VideoRenderer url={url} />}
    </div>
  );
};
