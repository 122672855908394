import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { SIDE_PADDING, SLIDE_GAP } from '#const/layout';
import { LectureIngList } from '#types/lectures';
import { LectureBox } from './LectureBox';
import { Container, CarouselArea, IndexArea, IndexIcon } from './styles';

interface Props {
  lecturesList?: Array<LectureIngList>;
}

export const LectureCarousel = ({ lecturesList }: Props) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalLecturesCount, setTotalLecturesCount] = useState(0);

  useEffect(() => {
    if (!lecturesList) return;

    const carouselRef = divRef.current;
    if (!carouselRef) return;

    setTotalLecturesCount(lecturesList.length);

    const handleScroll = (event: Event) => {
      const target = event.target as HTMLDivElement;
      const result =
        target.scrollLeft / (target.offsetWidth - 2 * SIDE_PADDING + SLIDE_GAP);

      if (Number.isInteger(result)) {
        setCurrentIndex(result);
      }
    };

    carouselRef.addEventListener('scroll', handleScroll);

    return () => {
      carouselRef.removeEventListener('scroll', handleScroll);
    };
  }, [lecturesList]);

  return (
    <Container>
      <CarouselArea ref={divRef}>
        {lecturesList?.length ? (
          lecturesList.map((lecture, idx) => (
            <LectureBox
              key={idx}
              hasData={true}
              lectureId={lecture.lecture.id}
              coachName={lecture.lecture.coachName}
              title={lecture.lecture.title}
              gymName={lecture.gymName}
              currentRound={
                lecture.calculatedRegisteredLecture
                  .registeredLectureFinishedCount
              }
              totalRound={
                lecture.calculatedRegisteredLecture.totalRegisteredLectureCount
              }
            />
          ))
        ) : (
          <LectureBox hasData={false} />
        )}
      </CarouselArea>
      {totalLecturesCount > 1 && (
        <IndexArea>
          {new Array(totalLecturesCount).fill(null).map((_, index) => (
            <IndexIcon
              key={index}
              className={classNames({ point: index === currentIndex })}
            />
          ))}
        </IndexArea>
      )}
    </Container>
  );
};
