import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Logo } from '#assets/svg';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import localStoreageManager from '#utils/localStoreageManager';
import {
  Container,
  CarouselContainer,
  CarouselViewport,
  CarouselItem,
  CarouselSlide,
  FirstCenterLine,
  SecondCenterLine,
  AlignContent,
  FirstTabConatiner,
  SecondTabConatiner,
  BottomProgressContainer,
  ProgressDotContainer,
  ProgresDot,
  BackgroundContiner
} from './styles';

interface Props {
  firstBackground: string;
  firstTexts: [string, string];
  secondBackground: string;
  secondTexts: [string, string];
  thirdTexts?: string;
  nextRoute: string;
}

export const textToLineBreakText = (str: string) => {
  const chunks = str.split('\n');
  return (
    <>
      {chunks.map((chunk, idx) => {
        if (idx === chunks.length - 1) return <Text key={idx}>{chunk}</Text>;
        return (
          <Text key={idx}>
            {chunk}
            <br />
          </Text>
        );
      })}
    </>
  );
};

export function WelcomeTemplate({
  firstBackground,
  firstTexts,
  secondBackground,
  secondTexts,
  thirdTexts,
  nextRoute
}: PropsWithChildren<Props>) {
  const [idx, setIdx] = useState<number>(0);
  const slideRef = useRef<HTMLDivElement>(null);
  const naviagte = useNavigate();

  const onSliderClick = () => {
    if (idx === 2) {
      naviagte(PATH.HOME);
    }
    setIdx((idx + 1) % 3);
  };

  useEffect(() => {
    if (!slideRef.current) return;
    slideRef.current.style.left = `${-idx * 100}%`;
  }, [idx]);

  return (
    <Container>
      <CarouselContainer>
        <CarouselViewport>
          <CarouselSlide ref={slideRef} count={3}>
            <CarouselItem>
              <BackgroundContiner src={firstBackground}>
                <AlignContent>
                  <FirstTabConatiner>
                    <Text textStyle="t2">{firstTexts[0]}</Text>
                    <FirstCenterLine />
                    {textToLineBreakText(firstTexts[1])}
                  </FirstTabConatiner>
                </AlignContent>
              </BackgroundContiner>
            </CarouselItem>
            <CarouselItem>
              <BackgroundContiner src={secondBackground}>
                <AlignContent>
                  <SecondTabConatiner>
                    <Text textStyle="t2">{secondTexts[0]}</Text>
                    <SecondCenterLine />
                    {textToLineBreakText(secondTexts[1])}
                  </SecondTabConatiner>
                </AlignContent>
              </BackgroundContiner>
            </CarouselItem>
            <CarouselItem>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  background: 'var(--color-point-dark-gradient)'
                }}
              >
                <AlignContent>
                  <Logo style={{ marginBottom: '1rem' }} />
                  <Text textStyle="t2">더 많은 수익을 제공하는</Text>
                  <Text textStyle="t2" className="color-text-point-gradient">
                    합리적인 PT 플랫폼
                  </Text>
                </AlignContent>
              </div>
            </CarouselItem>
          </CarouselSlide>
        </CarouselViewport>
      </CarouselContainer>
      <BottomProgressContainer>
        <ProgressDotContainer>
          <ProgresDot activate={idx === 0} />
          <ProgresDot activate={idx === 1} />
          <ProgresDot activate={idx === 2} />
        </ProgressDotContainer>
        <Button onClick={onSliderClick}>
          {idx === 2 ? 'fibud 시작하기' : '다음'}
        </Button>
        <div
          style={{ padding: 17 }}
          onClick={() => {
            localStoreageManager.readWelcome();
            naviagte(nextRoute);
          }}
        >
          <Text textStyle="c2">1 주일간 보지 않기</Text>
        </div>
      </BottomProgressContainer>
    </Container>
  );
}
