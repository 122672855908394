/* eslint-disable @typescript-eslint/no-explicit-any */

import { IPaymentsResponse, IRequestPay } from '#types/payments';
import sessionStorageManager from '#utils/sessionStorageManager';
import wni from '#utils/wni/lib/WNInterface';
import TagManager from 'react-gtm-module';
import { postPayments } from '.';

interface Props {
  data: IRequestPay;
  merchant_uid: string;
  onSuccess: (res: IPaymentsResponse) => void;
}

declare global {
  interface Window {
    IMP: {
      init: (id: string) => void;
      request_pay: any;
    };
  }
}

const lecturePayment = (
  result: any,
  data: IRequestPay,
  onSuccess: (res: IPaymentsResponse) => void
) => {
  const { setConfirmationFormId } = sessionStorageManager();

  // 공통 결제 후 처리 로직
  postPayments({
    impUid: result.imp_uid,
    lectureId: data.lectureId,
    count: data.count,
    gymId: data.gymId
  })
    .then((res: IPaymentsResponse) => {
      setConfirmationFormId(res.confirmationFormId);
      // 서버 결제 API 성공시 로직
      const tagManagerArgs = {
        dataLayer: {
          event: 'click_purchase',
          transaction_id: result.imp_uid,
          coach_id: data.coachId,
          purchase_price: data.amount * data.count
        }
      };
      TagManager.dataLayer(tagManagerArgs);

      onSuccess(res);
    })
    .catch(error => alert('결제 검증에 실패하였습니다.'));
};

const requestPay = ({ data, merchant_uid, onSuccess }: Props) => {
  const { IMP } = window;

  const paymentData = {
    // param
    pg: process.env.REACT_APP_PG_ID,
    // 실 연동 pg "danal_tpay.A010014568" 테스트용 "kakaopay.TC0ONETIME"
    pay_method: 'card', // card 등등
    name: data.lectureName,
    merchant_uid: merchant_uid, //* 백엔드에서 받기
    amount: data.amount * data.count, //총 결제 가격 (수업 1회 가격 * 회차 ) *필수
    buyer_email: data.userEmail, //로그인 한 사용자 이메일 *필수
    buyer_name: data.userName,
    buyer_tel: data.userPhone, //'010-2213-1111'
    buyer_addr: '경기도 성남시 수정구', // 주문자 주소, 기본값
    buyer_postcode: '01111', // 주문자 우편번호, 기본값
    custom_data: data.lectureId //lectureId
    // m_redirect_url: `http://192.168.0.8:3000${PATH.PAYMENT_SUCCESS}`
    // pg: 'danal_tpay.9810030929', //테스트결제용
  };

  const handlePaymentResult = (rsp: any) => {
    // native의 경우 rsp = {status: 'SUCCESS', result: rsp}
    if (rsp.success || rsp.status === 'SUCCESS') {
      lecturePayment(rsp.result || rsp, data, onSuccess);
    } else {
      alert(
        `결제에 실패하였습니다. 에러: ${rsp.error_msg || rsp.result.error_msg}`
      );
    }
  };

  if (wni.isNative) {
    return wni.execute('wnPaymentModule', {
      ...paymentData,
      callback: handlePaymentResult
    });
  }
  IMP.init(`${process.env.REACT_APP_MERCHANT_ID}`); // 가맹점 주소(피벗)
  IMP.request_pay(paymentData, handlePaymentResult);
};

export default requestPay;
