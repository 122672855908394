import { Dispatch, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { getAddress, getCoordinatesFromAddress } from '#api/location';
import { BoxIcon, Text } from '#atoms';
import {
  DEFAULT_LATITUDE,
  DEFAULT_LOCATION,
  DEFAULT_LONGITUDE
} from '#const/main/location';
import { useGetCurrentPosition } from '#hooks/useGetCurrentPosition';
import useNativeInit from '#hooks/useNativeInit';
import { AddressModal } from '#molecules';
import { useToast } from '#providers/ToastProvider';
import { useUserInfo } from '#providers/UserInfo';
import { $userLocationState } from '#states/atoms/userLocationState';
import sessionStorageManager from '#utils/sessionStorageManager';
import { useWNInterface } from '#utils/wni';
import { LocationArea } from './styles';
import Arrow from '#assets/svg/Arrow.svg';

interface LocationItemProps {
  setLoc?: React.Dispatch<React.SetStateAction<string>>;
}

export const LocationItem = ({ setLoc }: LocationItemProps) => {
  const { userInfo, patchProfile } = useUserInfo();
  const { currentLocation, currentAddress, setCurrentLocation } =
    sessionStorageManager();
  const setUserLocationState = useSetRecoilState($userLocationState);
  const { updateLocation } = useGetCurrentPosition();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addressModalOpen, setAddressModalOpen] = useState<boolean>(false);
  const { showToast } = useToast();
  const [address, setAddress] = useState<string>('');
  const nativeInterceptor = useNativeInit();
  const wni = useWNInterface();
  const isSameLocation =
    `${userInfo?.lat},${userInfo?.long}` !== currentLocation ||
    `${userInfo?.regionSigu} ${userInfo?.regionDong}` !== currentAddress;

  useEffect(() => {
    // 위치가 같으면 리렌더링 방지
    isSameLocation && handlePinClick();
  }, [userInfo]);

  useEffect(() => {
    currentAddress && setAddress(currentAddress);
    if (
      userInfo &&
      userInfo.lat &&
      userInfo.long &&
      userInfo.regionSigu &&
      userInfo.regionDong &&
      isSameLocation
    ) {
      // userInfo가 불러와지기 전에 이미 기본주소값이 첫 렌더링때 들어가므로 session 위치와 userInfo 위치를 비교
      setAddress(`${userInfo.regionSigu} ${userInfo.regionDong}`);
      setCurrentLocation({
        latitude: userInfo.lat,
        longitude: userInfo.long,
        address: `${userInfo.regionSigu} ${userInfo.regionDong}`,
        setLoc: setLoc
      });
      setUserLocationState({
        latitude: userInfo.lat,
        longitude: userInfo.long
      }); // 코치 조회 거리 필터 업데이트
      return;
    }
    if (!currentLocation || !currentAddress) {
      setCurrentLocation({
        address: '위치 설정하기',
        latitude: DEFAULT_LATITUDE,
        longitude: DEFAULT_LONGITUDE,
        setLoc: setLoc
      });
      setUserLocationState({
        latitude: DEFAULT_LATITUDE,
        longitude: DEFAULT_LONGITUDE
      });
    }
  }, [userInfo]);
  const handlePinClick = () => {
    if (!currentAddress) {
      setIsLoading(true);
      wni.execute('wnPermission', {
        type: 'location',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        callback: (options: any) => {
          if (options.status === 'GRANTED') {
            setIsLoading(false);
            wni.execute('wnLocationCurrent', {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              callback: (result: any) => {
                const { status, latitude, longitude } = result;
                getAddress(longitude, latitude).then(data => {
                  setCurrentLocation({
                    latitude,
                    longitude,
                    address: `${data.documents[0].address.region_2depth_name} ${data.documents[0].address.region_3depth_name}`,
                    setLoc: setLoc
                  });
                  setUserLocationState({
                    latitude,
                    longitude
                  });
                });
              }
            });
          }
        }
      });

      updateLocation()
        .then(location => {
          if (location) {
            setAddress(`${location.regionSigu} ${location.regionDong}`);
            setCurrentLocation({
              latitude: location.latitude,
              longitude: location.longitude,
              address: `${location.regionSigu} ${location.regionDong}`,
              setLoc: setLoc
            });
            setUserLocationState({
              latitude: location.latitude,
              longitude: location.longitude
            }); // 코치 조회 거리 필터 업데이트
            userInfo &&
              patchProfile(
                {
                  lat: location.latitude,
                  long: location.longitude,
                  regionSigu: location.regionSigu,
                  regionDong: location.regionDong
                },
                {
                  onSuccess: () =>
                    showToast({
                      message: '회원님의 위치정보가 갱신되었습니다.'
                    })
                }
              );
          } else {
            setCurrentLocation({
              address: `위치 설정하기`,
              latitude: DEFAULT_LATITUDE,
              longitude: DEFAULT_LONGITUDE,
              setLoc: setLoc
            });
            setUserLocationState({
              latitude: DEFAULT_LATITUDE,
              longitude: DEFAULT_LONGITUDE
            });
            setAddress('위치 설정하기');
          }
        })

        .finally(() => setIsLoading(false));
    }
  };

  const handleAddressClick = () => {
    setAddressModalOpen(true);
  };

  const onAddressComplete = (addressData: { address: string }) => {
    getCoordinatesFromAddress(addressData.address).then(data => {
      if (!data) return;
      const { region_2depth_name, region_3depth_name, x, y } =
        data.documents[0].address;
      setAddress(`${region_2depth_name} ${region_3depth_name}`);
      setCurrentLocation({
        latitude: Number(y),
        longitude: Number(x),
        address: `${region_2depth_name} ${region_3depth_name}`,
        setLoc: setLoc
      });
      setUserLocationState({
        latitude: Number(y),
        longitude: Number(x)
      }); // 코치 조회 거리 필터 업데이트
      userInfo &&
        patchProfile(
          {
            lat: Number(y),
            long: Number(x),
            regionSigu: region_2depth_name,
            regionDong: region_3depth_name
          },
          {
            onSuccess: () =>
              showToast({
                message: '회원님의 위치정보가 갱신되었습니다.'
              })
          }
        );
    });
    setAddressModalOpen(false);
  };

  return (
    <LocationArea>
      {/* <BoxIcon
        type="location-pin-point"
        onlyIcon
        size={22}
        onClick={handlePinClick}
      /> */}
      <Text
        onClick={handleAddressClick}
        textStyle="t3"
        textColor="white"
        style={{ fontStyle: 'normal', lineHeight: '100%', fontWeight: 600 }}
      >
        {isLoading ? '위치정보를 불러오는 중...' : address}
      </Text>
      <img src={Arrow} onClick={handleAddressClick} />
      {addressModalOpen && (
        <AddressModal
          onComplete={onAddressComplete}
          onCancel={() => {
            setAddressModalOpen(false);
          }}
        />
      )}
    </LocationArea>
  );
};
