import { useNavigate } from 'react-router';
import { BoxIcon, Button, Spacing, Text } from '#atoms';
import { PATH } from '#const/path';
import {
  RecordDetailExerciseRecordList,
  RecordDetailLectureInfo
} from '#organisims';
import { Dashboard, useDashboard } from '#providers/Dashboard';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import {
  ButtonContainer,
  CoachCommentContainer,
  PageContainer
} from './styles';

export function DashboardChartPage() {
  const navigate = useNavigate();
  const { lectureRecord } = useDashboard();

  return (
    <PageContainer>
      <PrevHeaderBarWithTitleTemplate
        prevRoute={PATH.DASHBOARD_CUSTOMER}
        title="운동차트"
        defaultBackground
      >
        <Dashboard.IsLoading>로딩중...</Dashboard.IsLoading>
        <Dashboard.IsError>잘못된 접근입니다!</Dashboard.IsError>
        <Dashboard.EmptyRecord>운동기록이 없습니다.</Dashboard.EmptyRecord>
        <Dashboard.HasRecord>
          <RecordDetailLectureInfo
            {...lectureRecord.registeredLectureRecord}
            isCoach
          />
          {lectureRecord.exerciseRecord?.length ? (
            <>
              <RecordDetailExerciseRecordList
                exerciseRecord={lectureRecord.exerciseRecord}
              />
              <CoachCommentContainer>
                <Text textStyle="t3">전문가 코멘트</Text>
                <Text textColor="gray2" style={{ whiteSpace: 'pre-wrap' }}>
                  {lectureRecord.registeredLectureRecord?.comment}
                </Text>
              </CoachCommentContainer>
              <Spacing size={30} />
              <ButtonContainer>
                <Button
                  onClick={() => {
                    navigate(PATH.DASHBOARD_CHART_FORM, {
                      state: { lectureRecord: lectureRecord }
                    });
                  }}
                >
                  수정하기
                </Button>
              </ButtonContainer>
            </>
          ) : (
            <Text
              textColor="gray2"
              style={{ textAlign: 'center', padding: '7rem 0' }}
            >
              운동차트가 아직 없습니다.
            </Text>
          )}
        </Dashboard.HasRecord>
      </PrevHeaderBarWithTitleTemplate>
    </PageContainer>
  );
}
