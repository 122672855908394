import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import {
  deleteImpossibleSchedules,
  postImpossibleSchedules
} from '#api/schedules';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { useAttendingLectures } from '#providers/AttendLecture';
import { useCoachSchedules } from '#providers/CoachSchedules';
import { CalendarDateHour, FibudDate, HourStatus } from '#providers/Schedules';
import { useSchedules } from '#providers/Schedules/Schedules';
import { useToast } from '#providers/ToastProvider';
import UserInfo from '#providers/UserInfo';
import { Container, TimeList, TimeStatusTable, TitleContainer } from './styles';

interface LectureSchedule {
  currentRound: number;
  totalRound: number;
}

interface ScheduleHourTabelProps {
  selectedDates?: FibudDate[];
  setSelectedDates?: React.Dispatch<React.SetStateAction<FibudDate[]>>;
  lectureSchedule?: LectureSchedule;
  onClickTime?: () => void;
}

export function ScheduleHourTable({
  selectedDates,
  setSelectedDates,
  lectureSchedule,
  onClickTime = () => {
    return;
  }
}: ScheduleHourTabelProps) {
  const { showToast } = useToast();

  const navigate = useNavigate();
  const { selectedDate, setSelectedDate } = useSchedules();
  const { updateScheduleList, updateImpossibleSchedules } = useCoachSchedules();
  const { selectedLecture, updateSelectedLecture } = useAttendingLectures();

  const submitImpossibleSchedules = () => {
    Promise.all(
      updateScheduleList.map(datehour => {
        if (datehour.status === HourStatus.AVAILABLE) {
          const cloneHour = datehour.time
            .clone()
            .set('minutes', 0)
            .set('seconds', 0)
            .set('milliseconds', 0);
          return postImpossibleSchedules(cloneHour.toISOString());
        } else {
          return deleteImpossibleSchedules(datehour.scheduledId ?? '');
        }
      })
    )
      .then(res => {
        showToast({
          message: '일정 수정에 성공하였습니다.'
        });
        navigate(PATH.MYPAGE);
      })
      .catch(() =>
        showToast({
          type: 'fail',
          message: '일정 수정에 실패하였습니다.'
        })
      );
  };

  const onClickItem = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    hour: number
  ) => {
    if (
      lectureSchedule !== undefined &&
      lectureSchedule?.currentRound >= lectureSchedule?.totalRound
    )
      return;

    selectedDate.dayjs.set('hours', hour);
    setSelectedDate({ ...selectedDate, hour });
    if (selectedDates && setSelectedDates) {
      setSelectedDates(prev => {
        const newDate = {
          ...selectedDate,
          hour,
          dayjs: selectedDate.dayjs.set('hour', hour)
        };
        const updatedDates = [...prev, newDate];

        return updatedDates.sort((a, b) => {
          if (a.year !== b.year) return a.year - b.year;
          if (a.month !== b.month) return a.month - b.month;
          if (a.date !== b.date) return a.date - b.date;
          return (a.hour || 0) - (b.hour || 0);
        });
      });
    }
  };

  const onClickCoachItem = useCallback(
    (dateHour: CalendarDateHour) => {
      updateImpossibleSchedules(dateHour);
    },
    [updateImpossibleSchedules]
  );

  const isTimeInSelectedDates = (selectedDates: FibudDate[], time: Dayjs) => {
    return selectedDates.some(
      element =>
        element.year === time.year() &&
        element.month === time.month() + 1 && // Adjust month to be 1-based
        element.date === time.date() &&
        element.hour === time.hour()
    );
  };

  const removeSelectedDate = useCallback((index: number) => {
    setSelectedDate(prev => {
      return { ...prev, hour: undefined };
    });
    if (setSelectedDates && selectedDates) {
      setSelectedDates(prev => {
        const updatedDates = prev.filter((_, i) => i !== index);
        return updatedDates.sort((a, b) => {
          if (a.year !== b.year) return a.year - b.year;
          if (a.month !== b.month) return a.month - b.month;
          if (a.date !== b.date) return a.date - b.date;
          return (a.hour || 0) - (b.hour || 0);
        });
      });
    }
  }, []);

  return (
    <Container>
      <TitleContainer>
        <Text textStyle="t3">시간 선택</Text>
        <TimeStatusTable>
          <li>
            <Text textStyle="c1">수업 가능 시간</Text>
            <span
              className="block"
              style={{ backgroundColor: 'var(--color-white)' }}
            />
          </li>
          <UserInfo.IsCoach>
            <li>
              <Text textStyle="c1">예약 완료 시간</Text>
              <span
                className="block"
                style={{ backgroundColor: 'var(--color-point)' }}
              />
            </li>
          </UserInfo.IsCoach>
          <UserInfo.ConsumerLike>
            <li>
              <Text textStyle="c1">선택 완료 시간</Text>
              <span
                className="block"
                style={{ backgroundColor: 'var(--color-point)' }}
              />
            </li>
          </UserInfo.ConsumerLike>
          <li>
            <Text textStyle="c1">수업 불가능 시간</Text>
            <span
              className="block"
              style={{ backgroundColor: 'var(--color-gray-second)' }}
            />
          </li>
        </TimeStatusTable>
      </TitleContainer>
      <Text textStyle="c1">오전</Text>
      <UserInfo.ConsumerLike>
        <TimeList>
          {selectedDate.timeList.map(({ time, status }) => {
            const disabled =
              status === HourStatus.UNAVAILABLE ||
              status === HourStatus.SCHEDULED;

            if (!selectedDates) return;
            return (
              time.hour() <= 12 && (
                <li
                  className={classNames({
                    disabled,
                    on: isTimeInSelectedDates(selectedDates, time)
                  })}
                  key={time.hour()}
                  onClick={event => {
                    if (
                      event.currentTarget.classList.contains('on') &&
                      setSelectedDates
                    ) {
                      setSelectedDates(prev =>
                        prev.filter(element => {
                          return !(
                            element.year === time.year() &&
                            element.month === time.month() + 1 &&
                            element.date === time.date() &&
                            element.hour === time.hour()
                          );
                        })
                      );
                    }
                    if (isTimeInSelectedDates(selectedDates, time)) return;
                    !disabled && onClickItem(event, time.hour());
                    onClickTime();
                  }}
                >
                  {time.hour().toString().padStart(2, '0')}:
                  {time.minute().toString().padStart(2, '0')}
                </li>
              )
            );
          })}
        </TimeList>
        <Text textStyle="c1">오후</Text>
        <TimeList>
          {selectedDate.timeList.map(({ time, status }) => {
            const disabled =
              status === HourStatus.UNAVAILABLE ||
              status === HourStatus.SCHEDULED;

            if (!selectedDates) return;
            return (
              time.hour() > 12 && (
                <li
                  className={classNames({
                    disabled,
                    on: isTimeInSelectedDates(selectedDates, time)
                  })}
                  key={time.hour()}
                  onClick={event => {
                    if (
                      event.currentTarget.classList.contains('on') &&
                      setSelectedDates
                    ) {
                      setSelectedDates(prev =>
                        prev.filter(element => {
                          return !(
                            element.year === time.year() &&
                            element.month === time.month() + 1 &&
                            element.date === time.date() &&
                            element.hour === time.hour()
                          );
                        })
                      );
                    }
                    if (
                      selectedDates?.find(
                        element =>
                          element.year === time.year() &&
                          element.month === time.month() + 1 &&
                          element.date === time.date() &&
                          element.hour === time.hour()
                      )
                    )
                      return;
                    !disabled && onClickItem(event, time.hour());
                    onClickTime();
                  }}
                >
                  {time.hour().toString().padStart(2, '0')}:
                  {time.minute().toString().padStart(2, '0')}
                </li>
              )
            );
          })}
        </TimeList>
      </UserInfo.ConsumerLike>
      <UserInfo.IsCoach>
        <TimeList>
          {selectedDate.timeList.map(dateHour => {
            const clickable = dateHour.status !== HourStatus.SCHEDULED;
            const disabled =
              (dateHour.status === HourStatus.UNAVAILABLE) !==
              updateScheduleList.includes(dateHour);
            return (
              dateHour.time.hour() <= 12 && (
                <li
                  className={classNames({
                    disabled,
                    reserved: dateHour.status === HourStatus.SCHEDULED,
                    on: selectedDate.hour === dateHour.time.hour()
                  })}
                  key={dateHour.time.hour()}
                  onClick={() => clickable && onClickCoachItem(dateHour)}
                >
                  {dateHour.time.hour().toString().padStart(2, '0')}:
                  {dateHour.time.minute().toString().padStart(2, '0')}
                </li>
              )
            );
          })}
        </TimeList>
        <Text textStyle="c1">오후</Text>
        <TimeList>
          {selectedDate.timeList.map(dateHour => {
            const clickable = dateHour.status !== HourStatus.SCHEDULED;
            const disabled =
              (dateHour.status === HourStatus.UNAVAILABLE) !==
              updateScheduleList.includes(dateHour);
            return (
              dateHour.time.hour() > 12 && (
                <li
                  className={classNames({
                    disabled,
                    reserved: dateHour.status === HourStatus.SCHEDULED,
                    on: selectedDate.hour === dateHour.time.hour()
                  })}
                  key={dateHour.time.hour()}
                  onClick={() => clickable && onClickCoachItem(dateHour)}
                >
                  {dateHour.time.hour().toString().padStart(2, '0')}:
                  {dateHour.time.minute().toString().padStart(2, '0')}
                </li>
              )
            );
          })}
        </TimeList>
        <Button
          onClick={() => submitImpossibleSchedules()}
          disabled={!updateScheduleList.length}
        >
          일정 수정하기
        </Button>
      </UserInfo.IsCoach>
    </Container>
  );
}

// class가 on이 아니고, selectedDates가 그 날에 하나라도 포함되어 있다면 selected를 true로 만든다.
