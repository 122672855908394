import dayjs from 'dayjs';
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { getCoachSchedules } from '#api/schedules';
import { ImpossibleSchedule } from '#types/api';
import { IVacation } from '#types/schedule';
import { HourStatus } from './const';
import { CaldenderRecipe, FibudDate } from './types';
import {
  convertCaldendarSchedules,
  convertRegisteredSchedules,
  dayJsToFibudDate
} from './utils';

interface ScheduleContext {
  selectedDate: FibudDate;
  currentDateTable: FibudDate[];
  monthOffset: number;
  currentMonth: FibudDate;
  nextMonth: () => void;
  prevMonth: () => void;
  setCoachId: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedDate: React.Dispatch<React.SetStateAction<FibudDate>>;
}

const ScheduleContext = createContext<ScheduleContext | null>(null);

export const useSchedules = () => {
  const context = useContext(ScheduleContext);
  if (!context) {
    throw new Error(
      'This component must be used within a <Schedules> component.'
    );
  }
  return context;
};

export function Schedules({ children }: PropsWithChildren) {
  const [today] = useState<FibudDate>(dayJsToFibudDate(dayjs()));
  const [coachId, setCoachId] = useState<string | null>(null);
  const [monthOffset, setMonthOffset] = useState<number>(0);
  const [currentMonth, setCurrentMonth] = useState<FibudDate>(today);
  const [currentDateTable, setCurrentDateTable] = useState<FibudDate[]>([]);
  const [selectedDate, setSelectedDate] = useState<FibudDate>({
    ...today,
    hour: undefined
  });

  const nextMonth = useCallback(() => {
    setMonthOffset(prev => prev + 1);
    const newMonthOffset = monthOffset + 1;
    const newMonth = dayjs().add(newMonthOffset, 'month');
    const endOfNextMonth = dayjs().add(1, 'month').endOf('month');

    if (newMonth.isBefore(endOfNextMonth, 'day')) {
      setMonthOffset(newMonthOffset);
      setCurrentMonth(dayJsToFibudDate(newMonth));
    }
  }, [monthOffset]);

  const prevMonth = useCallback(() => {
    // () => setMonthOffset(prev => prev - 1),
    const newMonthOffset = monthOffset - 1;
    const newMonth = dayjs().add(newMonthOffset, 'month');
    const startOfCurrentMonth = dayjs().startOf('month');

    if (!newMonth.isBefore(startOfCurrentMonth, 'day')) {
      setMonthOffset(newMonthOffset);
      setCurrentMonth(dayJsToFibudDate(newMonth));
    }
  }, [monthOffset]);

  useEffect(() => {
    const startOfMonth = today.dayjs.add(monthOffset, 'month').set('date', 1);
    const startIdx = startOfMonth.day();
    // 공백이 포함되어서 오는 케이스가 존재하는듯?
    const nextDateTable: FibudDate[] = [];

    // table 세팅
    for (let i = 0; i < startIdx; i++) {
      nextDateTable.push(
        dayJsToFibudDate(startOfMonth.subtract(startIdx - i, 'd'))
      );
    }

    for (let i = startIdx; i < 35; i++) {
      nextDateTable.push(dayJsToFibudDate(startOfMonth.add(i - startIdx, 'd')));
    }

    // 지금 보고 있는 달인 경우만 처리
    if (!monthOffset) {
      nextDateTable.forEach(date => {
        if (date.time < today.time) date.disabled = true;
      });
    }

    setCurrentMonth(dayJsToFibudDate(startOfMonth));

    if (coachId) {
      // const startAt = nextDateTable[0].dayjs.format('YYYY-MM-DD');
      const startAt = today.dayjs.startOf('day').format('YYYY-MM-DD');

      getCoachSchedules(coachId, startAt)
        .then(schedules => {
          // 휴가 정보
          const { vacations, impossibleSchedules } = schedules;
          // 코치가 넘겨준 스케쥴 기준 요일별 가능 시간 테이블
          const schedule = convertCaldendarSchedules(schedules);
          // 예약 된 스케쥴 목록
          const scheduledDates = convertRegisteredSchedules(
            schedules.registeredLectures
          );

          // 각 일정별 가능한 시간 추가 ( 모두 가능하다는 것으로 전제 )
          setupHours(nextDateTable, schedule);

          // 스케쥴에 등록되지 않은 날짜들은 disabled 처리
          setupDisabledDay(nextDateTable, schedule);

          // 휴가 일자 처리
          setupVacation(nextDateTable, vacations);

          // 불가능 일자 처리
          setupImpossibleDate(nextDateTable, impossibleSchedules);

          // 스케쥴이 등록된 날짜 지정
          setupScheduledDate(nextDateTable, scheduledDates);
          setCurrentDateTable([...nextDateTable]);
        })
        .catch((e: unknown) => {
          console.group('get coach schedules called error');
          console.log(e);
          console.groupEnd();
        });
    } else {
      setCurrentDateTable(nextDateTable);
    }
  }, [monthOffset, coachId]);

  useEffect(() => {
    // update timeList of initial selectedDate (today)
    const initialSelectedDate = currentDateTable.find(
      e => e.time === today.time
    );
    initialSelectedDate &&
      initialSelectedDate.time === selectedDate.time &&
      setSelectedDate({ ...initialSelectedDate, hour: undefined });
  }, [currentDateTable]);

  useEffect(() => {
    setMonthOffset(0);
    setSelectedDate({
      ...today,
      hour: undefined
    });
  }, [coachId]);

  const memorizedValue = useMemo(
    () => ({
      selectedDate,
      monthOffset,
      currentMonth,
      currentDateTable,
      setCoachId,
      setSelectedDate,
      nextMonth,
      prevMonth
    }),
    [
      selectedDate,
      monthOffset,
      currentMonth,
      currentDateTable,
      setCoachId,
      setSelectedDate,
      nextMonth,
      prevMonth
    ]
  );

  return (
    <ScheduleContext.Provider value={memorizedValue}>
      {children}
    </ScheduleContext.Provider>
  );
}

export default Schedules;

function setupHours(nextTable: Array<FibudDate>, schedule: CaldenderRecipe) {
  // Phase1. 스케쥴 목록을 기준으로 nextTable 업데이트
  const currentTime = dayjs();
  nextTable.forEach(date => {
    date.timeList = (schedule[date.day] ?? []).map(hour => {
      const time = dayjs()
        .set('years', date.year)
        .set('months', date.month - 1) // month는 0 - 11 이므로 -1
        .set('date', date.date)
        .set('hours', Math.floor(hour / 60))
        .set('minute', 0)
        .set('second', 0);

      if (date.day === currentTime.day() && time.isBefore(currentTime)) {
        // 가능한 일정 중 오늘 날짜가 있는 경우 현재 시간보다 이전 시간은 unavailable하도록 설정
        return {
          time,
          status: HourStatus.UNAVAILABLE
        };
      }
      return {
        time,
        status: HourStatus.AVAILABLE
      };
    });
  });
}

// function setupDisabledDay(
//   nextTable: Array<FibudDate>,
//   schedule: CaldenderRecipe
// ) {
//   const dates = Object.keys(schedule).map(v => Number(v));
//   if (dates.length === 7) return;
//   nextTable.forEach(date => (date.disabled ||= !dates.includes(date.day)));
// }
function setupDisabledDay(
  nextTable: Array<FibudDate>,
  schedule: CaldenderRecipe
) {
  const dates = Object.keys(schedule).map(v => Number(v));
  if (dates.length === 7) return;

  const oneMonthAhead = dayjs().add(1, 'month');

  nextTable.forEach(date => {
    if (date.dayjs.isAfter(oneMonthAhead, 'day')) {
      date.disabled = true;
    } else {
      date.disabled ||= !dates.includes(date.day);
    }
  });
}

function setupVacation(
  nextTable: Array<FibudDate>,
  vacations: Array<IVacation>
) {
  nextTable.forEach(date => {
    vacations.forEach(({ startAt, endAt }) => {
      const startAtDay = dayjs(startAt);
      const endAtDay = dayjs(endAt);
      const startDiff = startAtDay.diff(date.dayjs, 'dates');
      const endDiff = endAtDay.diff(date.dayjs, 'dates');
      if (startDiff <= 0 && endDiff >= 0) {
        date.disabled = true;
      }
    });
  });
}

function setupScheduledDate(
  nextTable: Array<FibudDate>,
  scheduledDates: Array<FibudDate>
) {
  scheduledDates.forEach(scheduledDate => {
    const item = nextTable.find(
      ({ year, month, date }) =>
        year === scheduledDate.year &&
        month === scheduledDate.month &&
        date === scheduledDate.date
    );

    if (item) {
      item.scheduled = true;
      item.timeList.forEach((time, index) => {
        if (time.time.get('hours') === scheduledDate.dayjs.hour()) {
          time.status = HourStatus.SCHEDULED;
          const nextTime = item.timeList[index + 1];
          if (
            nextTime &&
            nextTime.time.diff(scheduledDate.dayjs, 'minute') < 60
          ) {
            // 30분 단위로 예약된 경우 예외처리 - 12:30분 수업의 경우 13:00 타임 예약 불가하도록 함께 예약처리
            nextTime.status = time.status;
          }
        }
      });
    }
  });
}

function setupImpossibleDate(
  nextTable: Array<FibudDate>,
  impossibleSchedules: Array<ImpossibleSchedule>
) {
  impossibleSchedules.forEach(({ at, id }) => {
    const target = dayjs(at);
    const item = nextTable.find(
      ({ year, month, date }) =>
        year === target.year() &&
        month === target.month() + 1 &&
        date === target.date()
    );
    if (item) {
      const targetTime = item.timeList.find(
        ({ time }) => time.hour() === target.hour()
      );
      if (targetTime) {
        targetTime.scheduledId = id;
        targetTime.status = HourStatus.UNAVAILABLE;
      }
    }
  });
}
