import styled from 'styled-components';

interface MainHomeContainerProps {
  isLoggedUser?: boolean;
}

export const MainHomeContainer = styled.div<MainHomeContainerProps>`
  padding-bottom: ${({ isLoggedUser }) => (isLoggedUser ? 0 : 80)}px;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 2rem;

  &.trainer {
    height: 100%;
  }
`;

export const TemplateContainer = styled.div`
  padding: 27px 20px;
`;

export const InfoContainer = styled.div`
  padding: 3rem 0 2rem;
  color: var(--color-gray-text-second);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  > div {
    display: flex;
    gap: 1rem;
    align-items: center;
    > span {
      transform: rotate(90deg);
    }
    &.fold {
      padding-bottom: 0.5rem;
      > span {
        transform: rotate(-90deg);
      }
    }
  }
`;
