import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import {
  BillingDetailPage,
  BillingInvoicePage,
  BillingMainPage,
  BillingRegAccountPage,
  BillingRegRRNPage,
  BillingRegistrationPage,
  BillingRequestPage,
  BillingSuccessPage
} from '#pages';
import CoachBankNumber from '#providers/CoachBankNumber';
import CoachSettlements from '#providers/CoachSettlements';
import CoachSettlementsInfo from '#providers/CoachSettlementsInfo';
import { Container } from './styles';

export function BillingView() {
  return (
    <>
      <CoachBankNumber>
        <CoachBankNumber.IsLoading>로딩중...</CoachBankNumber.IsLoading>
        <CoachBankNumber.IsError>잘못된 접근입니다!</CoachBankNumber.IsError>
        <CoachBankNumber.IsEmpty>
          <Container>
            <Routes>
              <Route
                path=""
                element={<Navigate replace to="registration/rrn" />}
              />
              <Route
                path="registration/*"
                element={
                  <Routes>
                    {/* <Route path="" element={<BillingRegistrationPage />} /> */}
                    <Route path="rrn" element={<BillingRegRRNPage />} />
                    <Route path="account" element={<BillingRegAccountPage />} />
                  </Routes>
                }
              />
            </Routes>
          </Container>
        </CoachBankNumber.IsEmpty>
        <CoachBankNumber.HasData>
          <CoachSettlements>
            <CoachSettlementsInfo>
              <Routes>
                <Route path="" element={<BillingMainPage />} />
                <Route path="detail" element={<BillingDetailPage />} />
                <Route
                  path="request/*"
                  element={
                    <Routes>
                      <Route path="" element={<BillingRequestPage />} />
                      <Route path="invoice" element={<BillingInvoicePage />} />
                      <Route path="success" element={<BillingSuccessPage />} />
                    </Routes>
                  }
                />
              </Routes>
            </CoachSettlementsInfo>
          </CoachSettlements>
        </CoachBankNumber.HasData>
      </CoachBankNumber>
    </>
  );
}
