import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useGetGym } from '#api/gyms';
import { KakaoMap } from '#atoms';
import { GymLocationBottomSheet, GymLocationMapHeader } from '#organisims';
import { Container, StatusContainer } from './styles';

// 백엔드의 오류로 좌표값의 type이 string으로 반환되고 있음.
// 좌표값이 number 형태로 반환된다면 형변환 코드 제거 필요

export const GymLocationPage = () => {
  const [isBottomSheetExpand, setIsBottomSheetExpand] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    data: gymData,
    isSuccess,
    isError,
    isLoading
  } = useGetGym(location.state?.gymId ?? '');

  return (
    <Container>
      <GymLocationMapHeader onLeftButtonClick={() => navigate(-1)} />
      {isSuccess && (
        <>
          <KakaoMap
            latitude={Number(gymData.lat)}
            longitude={Number(gymData.long)}
            setIsBottomSheetExpand={setIsBottomSheetExpand}
          />
          <GymLocationBottomSheet
            name={gymData.name}
            address={gymData.address}
            phone={gymData.phone}
            images={gymData.images}
            introduction={gymData.introduction}
            isBottomSheetExpand={isBottomSheetExpand}
            setIsBottomSheetExpand={setIsBottomSheetExpand}
          />
        </>
      )}
      {isError && !gymData && (
        <StatusContainer>지도를 불러올 수 없습니다.</StatusContainer>
      )}
      {(isLoading || !gymData) && <StatusContainer>loading...</StatusContainer>}
    </Container>
  );
};
