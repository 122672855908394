import React, { useCallback, useState } from 'react';
import { useGetAllTags } from '#api/tags';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { GridCategory, GridCategoryType } from '#molecules';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { ITag } from '#types/tags';
import { categories } from './const';
import {
  ButtonContainer,
  Container,
  PaddingContainer,
  TextContainer
} from './styles';

export function MyPageApplicationsPurpose() {
  const [selectedCategory, setSelectedCategory] = useState<Array<ITag>>([]);
  const { data: tagsData } = useGetAllTags();

  const onChangeCategory = useCallback(
    (selectedItems: ITag[]) => setSelectedCategory(selectedItems),
    [selectedCategory]
  );

  return (
    <PrevHeaderBarWithTitleTemplate
      prevRoute={PATH.MYPAGE_APPLICATIONS}
      title="나의 신청서"
    >
      <PaddingContainer>
        <Container>
          <TextContainer>
            <Text textStyle="t1">PT수강 목적을 선택해주세요</Text>
          </TextContainer>
          <GridCategory
            categories={tagsData?.data ?? []}
            onChange={onChangeCategory}
          />
          <ButtonContainer>
            <Button>저장</Button>
          </ButtonContainer>
        </Container>
      </PaddingContainer>
    </PrevHeaderBarWithTitleTemplate>
  );
}
