import { useNavigate } from 'react-router';
import { usePatchReviews, usePostReviewImgs } from '#api/reviews';
import { PATH } from '#const/path';
import { useToast } from '#providers/ToastProvider';
import { createImgToFormData } from '#utils/createImgToFormData';
import { Image } from 'src/components/molecules/ImageWithUpload/hooks';

export interface SubmitReviewProps {
  registeredLectureId: string;
  description: string;
  localImgs: Image[];
}

export const useSubmitFinalReview = () => {
  const { mutateAsync } = usePatchReviews();
  const { mutateAsync: postReviewImgsMutate } = usePostReviewImgs();

  const navigate = useNavigate();
  const { showToast } = useToast();
  const submitReview = ({
    registeredLectureId,
    description,
    localImgs
  }: SubmitReviewProps) => {
    mutateAsync(
      {
        type: 'COACH',
        registeredLectureId,
        desc: description
      },
      {
        onSuccess: (res: string) => {
          if (localImgs.length > 0)
            postReviewImgsMutate(
              {
                rateId: res,
                payload: createImgToFormData(localImgs)
              },
              {
                onError: () => {
                  showToast({
                    type: 'fail',
                    message: '사진 등록에 실패했습니다.'
                  });
                },
                onSettled: () => {
                  navigate(PATH.FINAL_RATING_SUCCESS);
                }
              }
            );
          else navigate(PATH.FINAL_RATING_SUCCESS);
        },
        onError: () => {
          showToast({
            type: 'fail',
            message: '리뷰 등록에 실패했습니다.'
          });
        }
      }
    );
  };
  return submitReview;
};
