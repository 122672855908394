import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import { getPhoneNumberDuplicateCheck } from '#api/accounts';
import { getPhoneAuth, postPhoneAuth } from '#api/auth/signup';
import { Button, Text, Input } from '#atoms';
import { PATH } from '#const/path';
import { useCheckValidState } from '#hooks/useCheckValidState';
import { $signupState } from '#states/atoms/recoilSignupState';
import { PrevWithTitleTextTemplate } from '#templates';
import { InputArea, LineContainer, ButtonContainer } from './styles';

// TODO: 인증번호 발송 문제로 인증번호 기능 임시 제거, 추후 복구 예정

export function SignUpPhone() {
  const [signupState, setSignupState] = useRecoilState($signupState);
  const [phoneNumber, setPhoneNumber] = useState(signupState.phone ?? '');
  const [authNumber, setAuthNumber] = useState(signupState.authNumber ?? '');
  const [pushedButton, setPushedButton] = useState(false);
  const [message, setMessage] = useState('');
  const [isValidAuthNumber, setIsValidAuthNumber] = useState(
    signupState.authNumber ? true : false
  );
  const [isCorrectPhoneNumber, setIsCorrectPhoneNumber] = useState(false);
  const navigate = useNavigate();

  useCheckValidState({
    targetState: typeof signupState.isCoach === 'boolean',
    navigateTo: PATH.SIGN_UP_CATEGORY
  });

  const handleAuthButtonClick = async (number: string | null) => {
    if (number) {
      const isDuplicated = await getPhoneNumberDuplicateCheck(number);

      if (isDuplicated) {
        setMessage('이미 가입된 번호입니다');
        setPushedButton(false);
      }
      // else {
      //   try {
      //     await postPhoneAuth({ phone: number });
      //     setPushedButton(true);
      //     setMessage('');
      //   } catch (error) {
      //     setMessage('하루 인증 횟수를 초과하였습니다');
      //   }
      // }
    }
  };

  // useEffect(() => {
  //   if (phoneNumber && authNumber?.length === 6) {
  //     setIsValidAuthNumber(true);
  //     setMessage('');
  //   } else {
  //     setIsValidAuthNumber(false);
  //     setMessage('인증번호 6자리를 입력해주세요');
  //   }
  // }, [phoneNumber, authNumber]);

  // useEffect(() => {
  //   authNumber &&
  //     getPhoneAuth(phoneNumber, authNumber)
  //       .then(res => {
  //         setMessage('올바른 인증번호 입니다');
  //         setIsValidAuthNumber(true);
  //       })
  //       .catch(() => {
  //         setMessage('인증번호가 올바르지 않습니다');
  //         setIsValidAuthNumber(false);
  //       });
  // }, [authNumber]);

  useEffect(() => {
    const isValidPhoneNumber = /^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/.test(
      phoneNumber
    );
    setIsCorrectPhoneNumber(isValidPhoneNumber);

    if (!isValidPhoneNumber) {
      setMessage('올바른 형식의 번호를 입력해주세요.');
    } else {
      setMessage('올바른 형식의 전화번호입니다');
    }

    phoneNumber &&
      getPhoneNumberDuplicateCheck(phoneNumber).then(isDuplicated => {
        if (isDuplicated) {
          setMessage('이미 가입된 번호입니다');
          setIsCorrectPhoneNumber(false);
        }
      });
  }, [phoneNumber]);

  return (
    <PrevWithTitleTextTemplate
      prevRoute={PATH.SIGN_UP_POLICY}
      title={
        <Text textStyle="display">
          휴대폰 번호를
          <br />
          입력해주세요
        </Text>
      }
    >
      <LineContainer>
        <InputArea>
          <Input
            type="tel"
            placeholder="'-'를 제외하고 입력"
            labelText="휴대폰 번호"
            onChange={value => setPhoneNumber(String(value))}
            value={phoneNumber}
          />
          {/* <Button
            disabled={!isCorrectPhoneNumber}
            onClick={() => handleAuthButtonClick(phoneNumber)}
          >
            {pushedButton ? '재전송' : '인증번호 전송'}
          </Button> */}
        </InputArea>
        <Text>{message}</Text>
      </LineContainer>
      {/* <LineContainer>
        <Input
          type="tel"
          placeholder="인증번호 6자리를 입력해주세요"
          labelText="인증번호"
          onChange={value => setAuthNumber(String(value))}
          value={authNumber}
        />
      </LineContainer> */}
      <ButtonContainer>
        <Button
          // disabled={!isValidAuthNumber}
          disabled={!isCorrectPhoneNumber}
          onClick={() => {
            setSignupState(prev => ({
              ...prev,
              phone: phoneNumber,
              authNumber
            }));
            navigate(PATH.SIGN_UP_ID);
          }}
        >
          다음
        </Button>
      </ButtonContainer>
    </PrevWithTitleTextTemplate>
  );
}
