import Logo from '#assets/img/LOGO (1).svg';
import { PATH } from '#const/path';
import { TabListViewer } from '#molecules';
import UserInfo from '#providers/UserInfo';
import { HeaderBarTemplate } from '#templates';
import sessionStorageManager from '#utils/sessionStorageManager';
import { useNavigate } from 'react-router';
import { MainConsumerView } from './MainConsumerView';
import { Container, MyInfoNavigator } from './styles';

export function MainView() {
  const navigate = useNavigate();

  const { alarmLoginRedirectPath, removeSessionData } = sessionStorageManager();

  if (alarmLoginRedirectPath) {
    window.location.href = window.location.origin + alarmLoginRedirectPath;
    removeSessionData('alarmLoginRedirectPath');
  }

  const isPrerendering = window.navigator.userAgent === 'react-hydratable';

  if (isPrerendering) {
    return <div></div>;
  }

  return (
    <Container>
      <HeaderBarTemplate
        TitleLeft={
          <div
            style={{
              width: '100%',
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              top: '25%'
            }}
          >
            <img src={Logo} />
          </div>
        }
        // TitleCenter={<img src={Logo} style={{}} />}
        TitleRight={<MyInfoNavigator onClick={() => navigate(PATH.MYPAGE)} />}
      >
        <TabListViewer>
          <UserInfo.ConsumerLike>
            <MainConsumerView />
          </UserInfo.ConsumerLike>
        </TabListViewer>
      </HeaderBarTemplate>
    </Container>
  );
}
