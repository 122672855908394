import React, { useCallback } from 'react';
import { StyledButton } from './styles';

interface Props {
  background?: 'anti' | 'transparent';
  children: string;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
}

export const Button = ({
  children,
  background,
  disabled,
  onClick,
  isLoading
}: Props) => {
  const onClickCallback = useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(() => onClick && onClick(), [onClick]);

  return (
    <StyledButton
      className={background}
      disabled={disabled}
      isLoading={isLoading}
      onClick={onClickCallback}
    >
      {children}
    </StyledButton>
  );
};
