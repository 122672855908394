import dayjs from 'dayjs';
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useGetCoachSchedulePerWeek } from '#api/schedules';
import { FibudDate } from '#providers/Schedules/types';
import { dayJsToFibudDate } from '#providers/Schedules/utils';
import { CurrentDate } from '#types/date';
import {
  CoachSchedulePerWeekType,
  IGetCoachSchedulePerWeek,
  ReservedMembersType
} from '#types/schedule';
import { yearMonthDayFormatter } from '#utils/dateFormatter';
import { WeekDataType } from 'src/components/pages/WeeklyCalender';
import { getSundayOneWeekPrior, getWeekOfMonth } from './utils';

interface ScheduleManageContext {
  currentDate: CurrentDate;
  setCurrentDate: React.Dispatch<React.SetStateAction<CurrentDate>>;
  formatWeekData: CoachSchedulePerWeekType[];
  weekDayAndDates: WeekDataType[];
  weekData: IGetCoachSchedulePerWeek | undefined;
  startDateWeek: string;
  selectedLecture: ReservedMembersType;
  setSelectedLecture: React.Dispatch<React.SetStateAction<ReservedMembersType>>;
}

const ScheduleManageContext = createContext<ScheduleManageContext | null>(null);

export const useScheduleManage = () => {
  const context = useContext(ScheduleManageContext);
  if (!context) {
    throw new Error(
      'This component must be used within a <SchedulesManage> component.'
    );
  }
  return context;
};

function SchedulesManage({ children }: PropsWithChildren) {
  const [today] = useState<FibudDate>(dayJsToFibudDate(dayjs()));
  const dateDetails = getWeekOfMonth(today.dayjs);

  const [currentDate, setCurrentDate] = useState<CurrentDate>({
    year: today.year,
    month: today.month,
    week: dateDetails
  });

  const [selectedLecture, setSelectedLecture] = useState<ReservedMembersType>({
    startDate: '',
    endDate: '',
    id: '',
    gymId: '',
    isExistExerciseRecord: '',
    lectureName: '',
    lectureId: '',
    personId: '',
    personName: '',
    startAt: '',
    endedAt: '',
    taughtAt: '',
    round: 0,
    totalRound: 0,
    title: '',
    memo: ''
  });

  const startDateWeek = getSundayOneWeekPrior(
    currentDate.year,
    currentDate.month,
    currentDate.week
  );

  const { data: weekData } = useGetCoachSchedulePerWeek({
    startAt: startDateWeek,
    durationDays: 7
  });

  const formatWeekData: CoachSchedulePerWeekType[] =
    weekData?.map(item => ({
      date: yearMonthDayFormatter(item.date),
      reservedMembers: item.reservedMembers || []
    })) || [];

  const weekDayAndDates: WeekDataType[] = useMemo(() => {
    return Array.from({ length: 7 }).map((_, index) => ({
      day: dayjs(startDateWeek).add(index, 'day').format('dddd'),
      date: dayjs(startDateWeek).add(index, 'day').format('YYYY-MM-DD')
    }));
  }, [startDateWeek, currentDate]);

  const memorizedValue = useMemo(
    () => ({
      currentDate,
      setCurrentDate,
      formatWeekData,
      weekDayAndDates,
      weekData,
      startDateWeek,
      selectedLecture,
      setSelectedLecture
    }),
    [currentDate, formatWeekData, startDateWeek, selectedLecture]
  );
  return (
    <ScheduleManageContext.Provider value={memorizedValue}>
      {children}
    </ScheduleManageContext.Provider>
  );
}

export default SchedulesManage;
