// import dayjs from 'dayjs';
import { patchAccount } from '#api/accounts';
import {
  getConfirmationForms,
  postConfirmationForms
} from '#api/confirmation_forms';
import { useGetLecturesIngList } from '#api/lectures';
import { postPaymentsPrepare } from '#api/payments';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
// import { postRegisteredLectures } from '#api/registered-lectures';
// import { PATH } from '#const/path';
import { PATH } from '#const/path';
import { useToast } from '#providers/ToastProvider';
import { useUserInfo } from '#providers/UserInfo';
import { IGetConfirmationForm } from '#types/confirmationForms';
// import {
//   IGetRegisteredLecture,
//   IPostRegisteredLecture
// } from '#types/registeredLectures';
import {
  // convertRegisteredDateToScheduleDate,
  yearMonthDayFormatter
} from '#utils/dateFormatter';
import sessionStorageManager from '#utils/sessionStorageManager';
import { RegisterApplicationType } from './types';

interface Props {
  children?: React.ReactNode;
}

interface ApplicationsContext {
  isLoading: boolean;
  isError: boolean;
  applications: Array<IGetConfirmationForm>;
  currentApplication: Partial<RegisterApplicationType> | null;
  updateCurrentApplications: (
    pApplication: Partial<RegisterApplicationType>
  ) => void;
  clearCurrentApplications: () => void;
  // 불필요한 함수 (추후 사용 가능)
  // requestApplication: (
  //   lectureId: string,
  //   gymId: string,
  //   onSuccess: () => void
  // ) => void;
  requestPayment: (
    data: {
      lectureId: string;
      gymId: string;
      count: number;
    },
    onSuccess: (res: { merchant_uid: string; amount: number }) => void
  ) => void;
  postApplicationForm: (confirmationFormId?: string | null) => void;
  applicationPostComplete: boolean;
}

const ApplicationContext = React.createContext<ApplicationsContext | null>(
  null
);

export const useApplications = () => {
  const context = React.useContext(ApplicationContext);
  if (!context) {
    throw new Error(
      'This component must be used within a <Applications> component.'
    );
  }
  return context;
};

const initialHealthInfo = {
  height: 0,
  weight: 0,
  sleepingHourAvg: 0,
  qualityOfSleep: 0
};

function Applications({ children }: Props) {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const { userInfo } = useUserInfo();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [applications, setApplications] = useState<IGetConfirmationForm[]>([]);
  const [currentApplication, setCurrentApplication] = useState<
    Partial<RegisterApplicationType>
  >({});
  const [confirmationFormId, setConfirmationFormId] = useState<string>('');
  const [applicationPostComplete, setApplicationPostComplete] =
    useState<boolean>(false);
  const { data: savedConfirmationFormId } = useGetLecturesIngList();

  const latestConfirmationForm = useMemo(
    () =>
      applications.length > 0
        ? applications.reduce((latest, current) =>
            new Date(latest.createdAt) < new Date(current.createdAt)
              ? current
              : latest
          )
        : null,
    [applications]
  );

  const updateCurrentApplications = useCallback(
    (pApplication: Partial<RegisterApplicationType>) => {
      setCurrentApplication(prev => ({
        ...prev,
        ...pApplication,
        healthInfo: {
          ...prev.healthInfo,
          ...pApplication.healthInfo
        }
      }));
    },
    []
  );

  const clearCurrentApplications = useCallback(
    () => setCurrentApplication({}),
    []
  );

  const requestPayment = async (
    data: { lectureId: string; gymId: string; count: number },
    onSuccess: (res: { merchant_uid: string; amount: number }) => void
  ) => {
    postPaymentsPrepare({
      lectureId: data.lectureId,
      count: data.count
    })
      .then(res => {
        onSuccess(res);
      })
      .catch(() =>
        showToast({
          type: 'fail',
          message: '수강신청에 실패하였습니다.'
        })
      );
  };

  const postApplicationForm = () => {
    const { confirmationFormId } = sessionStorageManager();

    const id = confirmationFormId || '';

    postConfirmationForms(id, {
      healthInfo: {
        ...initialHealthInfo,
        ...currentApplication.healthInfo
      },
      purposeIds: currentApplication.purposes ?? [],
      etc: currentApplication.etc
    })
      .then(res => {
        setApplicationPostComplete(true);
        patchAccount({
          startAthletic: currentApplication.carrer,
          jobId: currentApplication.job
        });
        return res;
      })
      .catch(() => {
        showToast({
          type: 'fail',
          message: '수강신청에 실패하였습니다.'
        });
        navigate(PATH.APPLICATION_SCHEDULE_FAIL);
      });
  };

  //   sendingForms.then(res => {
  //     typeof res === 'string' && setConfirmationFormId(res);
  //     postPaymentsPrepare({
  //       lectureId: data.lectureId,
  //       count: data.count
  //     })
  //       .then(res => {
  //         onSuccess(res);
  //       })
  //       .catch(() =>
  //         showToast({
  //           type: 'fail',
  //           message: '수강신청에 실패하였습니다.'
  //         })
  //       );
  //   });

  const memorizedValue = useMemo<ApplicationsContext>(
    () => ({
      isLoading,
      isError,
      applications,
      currentApplication,
      updateCurrentApplications,
      clearCurrentApplications,
      // requestApplication,
      requestPayment,
      postApplicationForm,
      applicationPostComplete
    }),
    [
      applications,
      currentApplication,
      isLoading,
      isError,
      requestPayment,
      postApplicationForm,
      applicationPostComplete
    ]
  );

  useEffect(() => {
    if (userInfo) {
      setIsLoading(true);
      getConfirmationForms()
        .then(data => {
          setIsError(false);
        })
        .catch(() => setIsError(true))
        .finally(() => {
          setIsLoading(false);
        });
      setCurrentApplication({});
    } else {
      setIsLoading(false);
      setIsError(false);
      setApplications([]);
      setCurrentApplication({});
    }
  }, [userInfo]);
  useEffect(() => {
    if (latestConfirmationForm) {
      const { healthInfo, purposes, person, etc } = latestConfirmationForm;
      const application = {
        healthInfo:
          {
            height: Number(healthInfo.height),
            weight: Number(healthInfo.weight),
            sleepingHourAvg: healthInfo.sleepingHourAvg,
            qualityOfSleep: healthInfo.qualityOfSleep,
            diseases: healthInfo.diseases.map(e => ({
              diseaseKindId: e.kind.id
            })),
            diseaseEtc: healthInfo.diseaseEtc,
            painParts: healthInfo.painParts.map(e => ({
              kindId: e.kind.id
            })),
            painPartEtc: healthInfo.painPartEtc
          } || {},
        purposes: purposes.map(e => e.id) || [],
        carrer: yearMonthDayFormatter(person.startAthletic) || '',
        job: person.jobId || '',
        etc: etc || ''
      };
      setCurrentApplication(application);
    } else {
      setCurrentApplication({});
    }
  }, [latestConfirmationForm]);

  return (
    <ApplicationContext.Provider value={memorizedValue}>
      {children}
    </ApplicationContext.Provider>
  );
}

function IsLoading({ children }: Props) {
  const { isLoading } = useApplications();
  return isLoading ? <>{children}</> : null;
}

function IsError({ children }: Props) {
  const { isLoading, isError } = useApplications();
  return !isLoading && isError ? <>{children}</> : null;
}

function HasApplications({ children }: Props) {
  const { isLoading, isError, applications } = useApplications();
  return !isLoading && !isError && applications.length > 0 ? (
    <>{children}</>
  ) : null;
}

function EmptyApplications({ children }: Props) {
  const { isLoading, isError, applications } = useApplications();
  return !isLoading && !isError && applications.length === 0 ? (
    <>{children}</>
  ) : null;
}

Applications.IsLoading = IsLoading;
Applications.IsError = IsError;
Applications.HasApplications = HasApplications;
Applications.EmptyApplications = EmptyApplications;

export default Applications;

// requestPayment함수에서 postPaymentPrepare 의 스케줄 로직 불필요해서 주석

// const requestApplication = useCallback(
//   (lectureId: string, gymId: string, onSuccess: () => void) => {
//     Promise.all([
//       postConfirmationForms({
//         lectureId,
//         gymId,
//         healthInfo: {
//           ...initialHealthInfo,
//           ...currentApplication.healthInfo
//         },
//         purposeIds: currentApplication.purposes ?? [],
//         etc: currentApplication.etc
//       }).then(res => {
//         if (
//           currentApplication.schedules &&
//           currentApplication.schedules.length > 0
//         ) {
//           Promise.allSettled(
//             currentApplication.schedules.map((date, idx) => {
//               return postRegisteredLectures({
//                 lectureId,
//                 round: res?.calculatedRegisteredLecture.registeredLectureCount
//                   ? res.calculatedRegisteredLecture.registeredLectureCount +
//                     idx +
//                     1
//                   : idx + 1,
//                 taughtAt: dayjs(date).add(9, 'hour').toISOString(),
//                 gymId
//               });
//             })
//           ).then(results => {
//             const successResults = results
//               .filter(result => result.status === 'fulfilled')
//               .map(
//                 e =>
//                   (
//                     e as {
//                       status: 'fulfilled';
//                       value: Partial<IGetRegisteredLecture>;
//                     }
//                   ).value
//               );
//             const failedResults = results
//               .filter(result => result.status === 'rejected')
//               .map(e => {
//                 const reason = (
//                   e as {
//                     status: 'rejected';
//                     reason: AxiosError<Partial<IGetRegisteredLecture>>;
//                   }
//                 ).reason;

//                 if (reason && reason.config?.data) {
//                   try {
//                     const parsedData = JSON.parse(
//                       reason.config.data
//                     ) as IPostRegisteredLecture;
//                     return parsedData;
//                   } catch (error) {
//                     console.error('Error parsing JSON:', error);
//                   }
//                 }

//                 return null;
//               });

//             if (failedResults.length === 0) () => onSuccess();
//             else
//               navigate(PATH.APPLICATION_SCHEDULE_FAIL, {
//                 state: {
//                   lecture: confirmationFormState.lectureName,
//                   coach: confirmationFormState.coachName,
//                   gym: confirmationFormState.gymName,
//                   successRounds: successResults.map(e => e.round ?? 1),
//                   successSchedules: successResults.map(e =>
//                     convertRegisteredDateToScheduleDate(e.taughtAt ?? '')
//                   ),
//                   failedRounds: failedResults.map(e => e?.round ?? 1),
//                   failedSchedules: failedResults.map(e =>
//                     convertRegisteredDateToScheduleDate(e?.taughtAt ?? '')
//                   )
//                 }
//               });
//           });
//         }
//       }),
//       patchAccount({
//         startAthletic: currentApplication.carrer,
//         jobId: currentApplication.job
//       })
//     ])
//       .then(() => onSuccess())
//       .catch(error =>
//         showToast({
//           type: 'fail',
//           message: '수강신청에 실패하였습니다.'
//         })
//       );
//   },
//   [currentApplication]
// );
