import { useCallback, useEffect, useState } from 'react';
import { IconType } from '#atoms';
import { ITag } from '#types/tags';

interface HookProps {
  initialValue?: Array<ITag>;
  onChange?: (categories: Array<ITag>) => void;
  allowMultiple?: boolean;
}

export interface GridCategoryType {
  icon: IconType;
  text: string;
}

export const useGridCategory = ({
  initialValue,
  onChange,
  allowMultiple
}: HookProps) => {
  const [selectedCategory, setSelectedCategory] = useState<Array<ITag>>(
    initialValue ?? []
  );

  const toggleCategory = useCallback(
    (category: ITag) => {
      // if (allowMultiple) {
      if (selectedCategory.includes(category)) {
        setSelectedCategory(selectedCategory.filter(c => c !== category));
      } else {
        setSelectedCategory([...selectedCategory, category]);
      }
      // } else setSelectedCategory([category]);
    },
    [selectedCategory]
  );

  useEffect(() => {
    onChange && onChange(selectedCategory);
  }, [selectedCategory]);

  return {
    selectedCategory,
    toggleCategory
  };
};
