import { useNavigate } from 'react-router';
import { postBankNumbers } from '#api/banks';
import { PATH } from '#const/path';
import { useToast } from '#providers/ToastProvider';
import { IPostBankNumbers } from '#types/banks';
import { isNumeric } from '#utils/Numbers';

export const useAccountInput = ({
  e,
  setRegForm
}: {
  e: React.ChangeEvent<HTMLInputElement>;
  setRegForm: React.Dispatch<
    React.SetStateAction<
      IPostBankNumbers & {
        bankName: string;
      }
    >
  >;
}) => {
  const { value, name } = e.target;

  if (name !== 'number' || isNumeric(value) || value === '') {
    setRegForm(prev => ({ ...prev, [name]: value }));
  }
};

export const useSubmitBankNumber = () => {
  const { showToast } = useToast();

  const navigate = useNavigate();
  const submitBankNumber = (payload: IPostBankNumbers) => {
    postBankNumbers(payload)
      .then(() => {
        showToast({
          message: '수입관리 등록에 성공하였습니다.'
        });
        navigate(PATH.MYPAGE);
      })
      .catch((error: Error) => {
        showToast({
          type: 'fail',
          message: '수입관리 등록에 실패하였습니다.'
        });
      });
  };

  return submitBankNumber;
};
