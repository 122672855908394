import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useSetRecoilState } from 'recoil';
// import { Inquire } from '#assets/svg';
import { Button, Text } from '#atoms';
import {
  COACH_DETAIL_BUTTON_PADDING_BOTTOM,
  COACH_DETAIL_BUTTON_PADDING_TOP,
  HEADER_HEIGHT
} from '#const/layout';
import { PATH } from '#const/path';
import { useChat } from '#providers/Chat';
import { $modalFamily, MODAL_TYPE } from '#states/atoms/modalState';
import { ButtonSetWrap } from './styles';

interface Props {
  coachId: string;
  buttonLocation: React.MutableRefObject<number>;
  showButtonInBottom: boolean;
  onApplyClick?: () => void;
  onChatClick?: () => void;
}

export const ButtonSet = ({
  coachId,
  buttonLocation,
  showButtonInBottom,
  onApplyClick,
  onChatClick
}: Props) => {
  const divRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { openChat } = useChat();
  const setApplyModalState = useSetRecoilState($modalFamily(MODAL_TYPE.APPLY));
  // const setShowLoginModal = useSetRecoilState($modalFamily(MODAL_TYPE.LOGIN));

  const handleChatButtonClick = () => {
    onChatClick?.();
    const onSuccess = () => {
      navigate(PATH.CHAT_ROOM);
    };
    const onFail = () => {
      // setShowLoginModal(true);
      navigate(PATH.SIGN, {
        state: { blockedPagePath: location.pathname + location.search }
      });
    };
    openChat(coachId, onSuccess, onFail);
  };

  const handleApplyButtonClick = () => {
    setApplyModalState(true);
    onApplyClick?.();
  };

  useEffect(() => {
    const buttonRef = divRef.current;
    if (!buttonRef) return;
    const buttonRefLocation = buttonRef.getBoundingClientRect();

    buttonLocation.current =
      buttonRefLocation.bottom -
      HEADER_HEIGHT -
      COACH_DETAIL_BUTTON_PADDING_BOTTOM;
  }, []);

  return (
    <ButtonSetWrap
      ref={divRef}
      className={showButtonInBottom ? 'bottom' : ''}
      paddingBottom={COACH_DETAIL_BUTTON_PADDING_BOTTOM}
      paddingTop={COACH_DETAIL_BUTTON_PADDING_TOP}
    >
      <Button onClick={handleApplyButtonClick}>수업 신청</Button>
      {/* <Inquire onClick={handleChatButtonClick} /> */}
      <div className="chat" onClick={handleChatButtonClick}>
        <Text>채팅 상담</Text>
      </div>
    </ButtonSetWrap>
  );
};
