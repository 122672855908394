import styled from 'styled-components';

export const LecturePlacePageWrap = styled.div`
  display: flex;
  flex-direction: column;

  & > div:last-child {
    border-bottom: none;
  }
`;

export const LecturePlacePageArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  padding: 2rem;
  border-bottom: 1px solid var(--color-border);
`;

export const ImageList = styled.ul`
  display: flex;
  gap: 0.75rem;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const ImageItem = styled.li`
  width: 9.25rem;
  height: 9.25rem;

  img {
    width: 9.25rem;
    height: 9.25rem;
    object-fit: cover;
    border-radius: 0.5rem;
  }
`;

export const MainInfoArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const PlaceInfoArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem 0;
`;

export const MapContainer = styled.div`
  width: 100%;
  height: 28rem;
  border-radius: 0.8rem;
  overflow: hidden;
  z-index: 0;
`;

export const GymItemsContainer = styled.div`
  display: flex;
  gap: 1rem;

  > div {
    position: relative;
  }

  > div:hover .label {
    display: block;
  }
`;

export const GymItemLabel = styled.p`
  display: none;
  position: absolute;
  top: 80%;
  left: -50%;
  padding: 0.4rem 0.6rem;
  width: max-content;
  border-radius: 5px;
  background-color: var(--color-border);
`;
