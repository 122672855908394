import styled from 'styled-components';

export const TemplateContainer = styled.div`
  width: 100%;
  height: 100dvh;
`;
export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const ChatListContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 9.5rem);
  padding: 1rem 2rem;
  overflow: auto;
`;

export const ChatInputContainer = styled.div`
  position: relative;
  width: 100%;
  height: 9.5rem;
  padding: 1rem 2rem 0;
  background-color: var(--color-gray-second);
`;

export const MessageBox = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 1rem;
  gap: 0.5rem;

  & > p {
    max-width: 80%;
    border-radius: 1rem;
    background-color: var(--color-point);
    padding: 1rem;
    word-break: break-all;
  }

  &.mine {
    justify-content: flex-end;

    & > p {
      background-color: var(--color-gray-second);
    }

    & > div {
      align-items: flex-end;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .mine {
      align-items: flex-start;
    }
  }
`;

export const InputBox = styled.input`
  width: 100%;
  height: 4rem;
  border-radius: 2rem;
  color: var(--color-white);
  background-color: var(--color-gray);
  padding: 0 1rem;
  border: none;
  outline: none;
`;

export const DateBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem;
`;

export const ChatInfo = styled.div`
  width: 100%;
  text-align: center;
`;
