import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetAverageReviews } from '#api/reviews';
import { Button, Text, Textarea } from '#atoms';
import { REVIEW_LIST } from '#const/review';
import { ImageWithUpload, TitleItemBox } from '#molecules';
import { HeaderBarTemplate } from '#templates';
import { imageFileType } from '#types/fileType';
import { dottedDateFormatter } from '#utils/dateFormatter';
import { useUploadImages } from 'src/components/molecules/ImageWithUpload/hooks';
import { RatingStars } from 'src/components/molecules/RatingStar';
import { useSubmitFinalReview } from './hooks';
import {
  Container,
  TextArea,
  InfoWrapper,
  BottomArea,
  ReviewCategory,
  ReviewListWrapper,
  StarArea,
  PageContainer
} from './styles';

export const FinalRatingPage = () => {
  const location = useLocation();
  const { lectureId, registeredLectureId } = location.state as {
    lectureId: string;
    registeredLectureId: string;
  };
  const { data } = useGetAverageReviews(lectureId ?? '');
  const [reviewText, setReviewText] = useState<string>('');
  const { localImgs, handleUploadImages, handleDeleteLocalImage } =
    useUploadImages({
      fileType: 'image'
    });
  const submitReview = useSubmitFinalReview();

  const handleSubmit = () => {
    submitReview({
      registeredLectureId,
      description: reviewText,
      localImgs
    });
  };

  if (!data) return null;

  return (
    <PageContainer>
      <HeaderBarTemplate TitleCenter={<Text textStyle="t3">리뷰 등록</Text>}>
        <Container>
          <TextArea>
            <Text children="마지막 리뷰를 등록해주세요" textStyle="t1" />
            <div>
              <Text textColor="gray2" textStyle="t3">
                {dottedDateFormatter(data.startAt)} ~{' '}
                {dottedDateFormatter(data.endAt)}
              </Text>
              <InfoWrapper>
                <Text textColor="gray2" textStyle="t3">
                  {data.lecture.title}
                </Text>
                <Text textColor="gray2" textStyle="t3">
                  {data.lecture.coachName} 전문가
                </Text>
              </InfoWrapper>
            </div>
          </TextArea>
          <BottomArea>
            <ReviewListWrapper>
              {REVIEW_LIST.map(({ id, title }) => (
                <ReviewCategory key={id}>
                  <Text textStyle="bb">{title}</Text>
                  <StarArea>
                    <RatingStars mode="register" rate={data[id]} />
                  </StarArea>
                </ReviewCategory>
              ))}
            </ReviewListWrapper>
            <Textarea
              height={19}
              limit={500}
              value={reviewText}
              onChange={setReviewText}
              placeholder="수업 리뷰를 적어주세요"
            />
            <TitleItemBox title="사진 첨부" type="images" isOptional>
              <ImageWithUpload
                localImages={localImgs}
                onUploadImage={handleUploadImages}
                onDeleteLocalImage={handleDeleteLocalImage}
                fileType={imageFileType}
              />
            </TitleItemBox>
            <Button
              children="리뷰 등록하기"
              onClick={handleSubmit}
              disabled={!reviewText}
            />
          </BottomArea>
        </Container>
      </HeaderBarTemplate>
    </PageContainer>
  );
};
