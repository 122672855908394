import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { usePostReviewsMutation } from '#api/reviews';
import { StarRater, Button, ModalPortal, Text, BoxIcon } from '#atoms';
import { PATH } from '#const/path';
import { useToast } from '#providers/ToastProvider';
import { useUserInfo } from '#providers/UserInfo';
import { ILectureRate } from '#types/rates';
import {
  Background,
  ClassEvaluationModalContainer,
  EvaluationBox,
  FinalRateBox
} from './styles';

interface Props {
  registeredLectureId: string;
  round: number;
  isFinal?: boolean;
  lectureId: string;
  onClose: () => void;
}

export const ClassEvaluationModal = ({
  registeredLectureId,
  round,
  isFinal,
  lectureId,
  onClose
}: Props) => {
  const navigate = useNavigate();
  const [rate, setRate] = useState<ILectureRate>({
    coachNice: 0,
    lectureQuality: 0,
    timeNice: 0
  });
  const [step, setStep] = useState(0);
  const { fetchTodayLectures } = useUserInfo();
  const onRateChange = (rate: Partial<ILectureRate>) => {
    setRate(prev => ({ ...prev, ...rate }));
  };
  const { mutateAsync } = usePostReviewsMutation();
  const { showToast } = useToast();

  const handleReviewSubmitButtonClick = () => {
    mutateAsync(
      {
        type: 'LECTURE',
        registeredLectureId,
        ...rate
      },
      {
        onSuccess: () => {
          fetchTodayLectures();
          setStep(prev => prev + 1);
        },
        onError: () => {
          showToast({
            type: 'fail',
            message: '리뷰 등록에 실패했습니다.'
          });
        }
      }
    );
  };

  const handleFinalRateButtonClick = () => {
    onClose();
    navigate(PATH.FINAL_RATING, { state: { lectureId, registeredLectureId } });
  };

  return (
    <ModalPortal>
      <Background>
        <ClassEvaluationModalContainer>
          {step === 0 && (
            <>
              <Text textStyle="t2" textColor="point">
                {round}회차 수업 완료
              </Text>
              <BoxIcon type="check-circle-point" onlyIcon size={85} />
              <EvaluationBox>
                <div>
                  <Text>친절도</Text>
                  <StarRater
                    rating={rate.coachNice}
                    onInputRating={(value: number) =>
                      onRateChange({ coachNice: value })
                    }
                  />
                </div>
                <div>
                  <Text>전문성</Text>
                  <StarRater
                    rating={rate.lectureQuality}
                    onInputRating={(value: number) =>
                      onRateChange({ lectureQuality: value })
                    }
                  />
                </div>
                <div>
                  <Text>시간 약속</Text>
                  <StarRater
                    rating={rate.timeNice}
                    onInputRating={(value: number) =>
                      onRateChange({ timeNice: value })
                    }
                  />
                </div>
              </EvaluationBox>
              <Button
                onClick={handleReviewSubmitButtonClick}
                disabled={
                  rate.coachNice === 0 ||
                  rate.lectureQuality === 0 ||
                  rate.timeNice === 0
                }
              >
                평점 등록하기
              </Button>
            </>
          )}
          {step === 1 &&
            (isFinal ? (
              <>
                <FinalRateBox>
                  <Text textStyle="t2" textColor="point">
                    모든 회차 수업이
                    <br />
                    마무리되었습니다
                  </Text>
                  <BoxIcon type="check-circle-point" onlyIcon size={85} />
                  <Text textStyle="t2" textColor="point">
                    마지막 리뷰를 등록해주세요
                  </Text>
                </FinalRateBox>
                <Button onClick={handleFinalRateButtonClick}>
                  리뷰 등록하기
                </Button>
              </>
            ) : (
              <>
                <FinalRateBox>
                  <Text textStyle="t2" textColor="point">
                    {round}회차 수업이
                    <br />
                    마무리되었습니다
                  </Text>
                  <BoxIcon type="check-circle-point" onlyIcon size={85} />
                </FinalRateBox>
                <Button onClick={() => onClose()}>확인</Button>
              </>
            ))}
        </ClassEvaluationModalContainer>
      </Background>
    </ModalPortal>
  );
};
