import React from 'react';
import { BoxIcon, Text } from '#atoms';
import { REVIEW_LIST } from '#const/review';
import { ILectureRate } from '#types/rates';
import { IRegisteredLectureRecord } from '#types/registeredLectures';
import { dateDayFormatter } from '#utils/dateFormatter';
import { RatingStars } from 'src/components/molecules/RatingStar';
import {
  Container,
  LectureUserNameWrapper,
  LectureInfoWrapper,
  LectureInfoBox,
  ReviewLists,
  ReviewCategory,
  StarArea
} from './styles';

export const RecordDetailLectureInfo = ({
  name,
  taughtAt,
  round,
  gym,
  coachName,
  isCoach,
  coachNice,
  lectureQuality,
  timeNice
}: Partial<IRegisteredLectureRecord> & Partial<ILectureRate>) => {
  const reviewRates = [coachNice ?? 0, lectureQuality ?? 0, timeNice ?? 0];

  return (
    <Container>
      <LectureUserNameWrapper>
        <Text textStyle="t2">{name}</Text>
        <Text textStyle="t2">회원님 운동기록</Text>
      </LectureUserNameWrapper>
      <LectureInfoWrapper>
        <LectureInfoBox>
          {taughtAt && (
            <time dateTime={taughtAt} data-text-type="t3">
              {dateDayFormatter(taughtAt)}
            </time>
          )}
          {!isCoach && <Text textStyle="t3">{round}회차</Text>}
        </LectureInfoBox>
        <LectureInfoBox>
          <Text textStyle="t3">{gym}</Text>
          {isCoach ? (
            <Text textStyle="t3">{round}회차</Text>
          ) : (
            <Text textStyle="t3">{coachName} 전문가</Text>
          )}
        </LectureInfoBox>
        {!isCoach && (
          <ReviewLists>
            {REVIEW_LIST.map(({ id, title }, index) => (
              <ReviewCategory key={id}>
                <Text textStyle="bb">{title}</Text>
                <StarArea>
                  <RatingStars mode="register" rate={reviewRates[index]} />
                </StarArea>
              </ReviewCategory>
            ))}
            <Text textStyle="c2" style={{ textAlign: 'center' }}>
              전문가는 별점을 볼 수 없습니다
            </Text>
          </ReviewLists>
        )}
      </LectureInfoWrapper>
    </Container>
  );
};
