import React from 'react';
import { Button, SelectBox, Text } from '#atoms';
import { PATH } from '#const/path';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import {
  ButtonContainer,
  Container,
  PaddingContainer,
  TextContainer
} from './styles';

export const STARTDATE_LIST = [
  '경력없음',
  '1년',
  '2년',
  '3년',
  '4년',
  '5년 이상'
];

export function MyPageApplicationsCareer() {
  return (
    <PrevHeaderBarWithTitleTemplate
      prevRoute={PATH.MYPAGE_APPLICATIONS}
      title="나의 신청서"
    >
      <PaddingContainer>
        <Container>
          <TextContainer>
            <Text textStyle="t1">
              회원님의 운동경력을
              <br />
              입력해주세요
            </Text>
          </TextContainer>
          <SelectBox
            placeholder="운동경력을 선택해주세요"
            data={STARTDATE_LIST}
            onChange={() => console.log('')}
          />
          <ButtonContainer>
            <Button>저장</Button>
          </ButtonContainer>
        </Container>
      </PaddingContainer>
    </PrevHeaderBarWithTitleTemplate>
  );
}
