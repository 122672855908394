import request from '#api/axios';
import { IPostInquiries } from '#types/api';

export const postInquiries = async (payload: IPostInquiries) => {
  const res = await request({
    method: 'post',
    url: '/inquiries/',
    data: payload
  });

  return res.data;
};
