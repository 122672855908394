import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { ChatHome, ChatRoom } from '#pages';
import { useChat } from '#providers/Chat';
import { FibudContainerTemplate } from '#templates';

export function ChatView() {
  const navigate = useNavigate();
  // const location = useLocation();
  // const { setCurrentRoom } = useChat();

  // useEffect(() => {
  //   if (!location.state || !location.state?.room) return;
  //   setCurrentRoom(location.state.room);
  //   navigate('room');
  // }, [location.state]);

  return (
    <FibudContainerTemplate>
      <Routes>
        <Route path="" element={<ChatHome />} />
        <Route path="update" />
        <Route path="room" element={<ChatRoom />} />
      </Routes>
    </FibudContainerTemplate>
  );
}
